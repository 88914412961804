import * as actions from "./action-types";

export function toggleFaqModal() {
  return {
    type: actions.TOGGLE_FAQ_MODAL
  };
}

export function toggleTermsModal() {
  return {
    type: actions.TOGGLE_TERMS_MODAL
  };
}

export function togglePrivacyModal() {
  return {
    type: actions.TOGGLE_PRIVACY_MODAL
  };
}

export function toggleContactModal() {
  return {
    type: actions.TOGGLE_CONTACT_MODAL
  };
}

export function toggleTakePartModal() {
  return {
    type: actions.TOGGLE_TAKE_PART_MODAL
  }
}

export function toggleLanguageModal() {
  return {
    type: actions.TOGGLE_LANGUAGE_MODAL
  };
}

export function selectLanguage(payload) {
  return {
    type: actions.SELECT_LANGUAGE,
    payload
  };
}
