import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import {FormattedMessage} from 'react-intl';
import ROLLBAR from '../../../rollbar.js';
import thumbsUp from '../thumbsUp.png';
import progressTimer from '../progressTimer';
import ErrorHandler from '../../util/errorHandler';
import renderQuestions from '../../questionLayout/questionLayout';
import StudyNextController from '../../studyController/studyNextController';
import {MOVENEXT_TYPES} from '../../../types/types';
import getDeviceInfo from '../../util/deviceValidation';

export default class LSQuestionStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      completedForm: false,
      currentQuestionIndex: 0,
    }

    this.questionError = React.createRef();

    this.checkForCompletion = this.checkForCompletion.bind(this);
    this.updateQuestionView = this.updateQuestionView.bind(this);
    this.canProgress = this.canProgress.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
     if(this.state.completedForm != nextState.completedForm) {
          return false
     }
     return true
   }

  checkForCompletion() {
    let currentStepOrderId = this.props.studyReducer.currentStepOrderId;
    const form = document.querySelector('#ls-questions');
    const formData = Object.fromEntries(new FormData(form).entries());
    let responseArr = [];
    for (let [key, value] of Object.entries(formData)) {
      responseArr.push(value != "");
    }
    let formDataValidity = !responseArr.includes(false);
    if (Object.keys(formData).length >= this.props.studyReducer.stepData[currentStepOrderId].question_data.length && formDataValidity) {
      if (this.state.completedForm != true) {
        this.setState({
          ...this.state,
          completedForm: true
        })
      }
      this.props.progressAvailableTrue();
    } else {
      if (this.state.completedForm) {
        this.setState({
          ...this.state,
          completedForm: false
        })
      }
      this.props.progressAvailableFalse();
    }
  }

  canProgress() {
    this.questionError.current.style.display = "none";
    let currentStepOrderId = this.props.studyReducer.currentStepOrderId;
    let questionData = this.props.studyReducer.stepData[currentStepOrderId].question_data;
    let questionLength = this.props.studyReducer.stepData[currentStepOrderId].question_data.length;

    if (questionData[this.state.currentQuestionIndex + 1].movenext_type_id == MOVENEXT_TYPES.MOVENEXT_TYPE_NOBACK) {
      // if question is text
      if (questionData[this.state.currentQuestionIndex].type_id == 3 || questionData[this.state.currentQuestionIndex].type_id == 4) {
        if (document.querySelector("#q" + questionData[this.state.currentQuestionIndex].id + "a0").value != "") {
          return true
        } else {
          this.questionError.current.style.display = "block";
          return false
        }
      } else {
        const form = document.querySelector('#ls-questions');
        const formData = Object.fromEntries(new FormData(form).entries());
        let remainingTextQuestionsCount = questionData.filter((question, index) => (question.type_id == 3 || question.type_id == 4) && index > this.state.currentQuestionIndex).length;
        var currentFormSize = Object.keys(formData).length;
        if (currentFormSize - remainingTextQuestionsCount >= this.state.currentQuestionIndex + 1) {
          return true
        } else {
          this.questionError.current.style.display = "block";
          return false
        }
      }
    }

    return true
  }

  updateQuestionView(incr) {
    let currentStepOrderId = this.props.studyReducer.currentStepOrderId;
    let questionLength = this.props.studyReducer.stepData[currentStepOrderId].question_data.length;
    document.querySelector('.questionRow').scrollTo(0,0);
    if (incr == 1 && this.state.currentQuestionIndex < questionLength - 1 && this.canProgress()) {
      this.setState({
        ...this.state,
        currentQuestionIndex: this.state.currentQuestionIndex += 1
      })
      return
    } else if (incr == 1 && this.state.completedForm) {
      StudyNextController.submitQuestionStep();
      return
    }

    // error message handling
    if (incr == 1 && this.state.currentQuestionIndex == questionLength - 1 && !this.state.completedForm) {
      this.questionError.current.style.display = "block";
      return
    }

    if (incr == -1 && this.state.currentQuestionIndex > 0) {
      this.setState({
        ...this.state,
        currentQuestionIndex: this.state.currentQuestionIndex -= 1
      })
      return
    }
  }

  componentDidMount() {
    window.LRSPGlobalHandler_updateQuestionView = this.updateQuestionView;
  }

  componentWillUnmount() {
    delete window.LRSPGlobalHandler_updateQuestionView;
  }

  render() {
    let currentStepOrderId = this.props.studyReducer.currentStepOrderId;
    let questionLength = this.props.studyReducer.stepData[currentStepOrderId].question_data.length;
    let questionData = this.props.studyReducer.stepData[currentStepOrderId].question_data;
    return (
      <Row className="questionRow">
      <Col xs={12}>
          <form id="ls-questions" onClick={this.checkForCompletion} onSubmit={e => { e.preventDefault(); }}>
            {renderQuestions(questionData, this.state.currentQuestionIndex, this.checkForCompletion)}
          </form>
          <p ref={this.questionError} style={{display: "none"}} className="questionError"><FormattedMessage id="app.questions.required" defaultMessage="This question is required." description="Required answer"/></p>
          {this.state.currentQuestionIndex > 0 && questionData[this.state.currentQuestionIndex].movenext_type_id != MOVENEXT_TYPES.MOVENEXT_TYPE_NOBACK ? <button style={{marginRight: "10px"}} className="btn btn-question" onClick={() => {this.updateQuestionView(-1)}}><span>&#8592;</span></button> : null}
          {getDeviceInfo().isMobile ? null : <button className="btn btn-question" onClick={() => {this.updateQuestionView(1)}}><span>&#8594;</span></button>}
        </Col>
      </Row>
    );
  }
}
