import React from 'react';
// DEMO ALT
import StudyNextController from 'components/studyController/studyNextController';
import { Player, ControlBar, PlayToggle, VolumeMenuButton } from 'video-react';
import ROLLBAR from '../../../../rollbar.js';
import progressTimer from '../../../../components/studyController/progressTimer';

export default class LSStudyStimulusStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stimSrc: null,
      stimHtmlConstructorType: null,
      stimStyle: null
    }

    this.storedStepId = null;
    this.playerSubscribed = false;
    this.endHandled = false;

    this.htmlConstructor = this.htmlConstructor.bind(this);
    this.handleError = this.handleError.bind(this);
    this.internalRender = this.internalRender.bind(this);
  }

  htmlConstructor() {
    var currentStep = this.props.currentStepOrderId;

    let stimStyle;
    let stimWidth = this.props.stepData[currentStep].stim_width || "auto";
    let stimHeight = this.props.stepData[currentStep].stim_height || "auto";
    let stimComp = typeof stimWidth == "number" && typeof stimHeight == "number";

    if (this.state.stimStyle === "scroll") {
      stimStyle = {
        width: "100%",
        overflow: "scroll"
      }
    } else if (stimComp && stimWidth > stimHeight) {
      stimStyle = {
        width: stimWidth
      }
    } else if (stimComp && stimWidth < stimHeight) {
      stimStyle = {
        height: stimHeight
      }
    } else {
      stimStyle = {
        height: stimHeight,
        width: stimWidth
      }
    }

    switch (this.state.stimHtmlConstructorType) {
      case "img":
        if (this.state.stimStyle === "scroll") {
          return <div className="ls-scrollCont" style={stimStyle}><img className="ls-scrollImg" id="lumenStim" alt="Lumen stim" src={this.state.stimSrc}></img></div>;
        } else {
          return <img style={stimStyle} id="lumenStim" alt="Lumen stim" src={this.state.stimSrc}></img>;
        }
      case "video":
        // TODO: Test video
        this.playerSubscribed = false;
        this.endHandled = false;
        const controlsBool = this.props.stepData[currentStep].video_showcontrols;
        const mutedBool = this.props.stepData[currentStep].video_mute;
        const autoPlay = this.props.stepData[currentStep].video_autoplay;
        return <Player muted={mutedBool} videoId="lumenStim" fluid={false} width={stimStyle.width} height={stimStyle.height} ref={(player) => { this.player = player }} autoPlay={autoPlay} playsInline src={this.state.stimSrc}>
                <ControlBar autoHide={false} disableDefaultControls={true} disableCompletely={!controlsBool}>
                  <PlayToggle />
                  <VolumeMenuButton />
                </ControlBar>
               </Player>
      default:
        return null
    }
  }

  handleError(userId, success) {
    if (!success) {
      this.props.errorState();
    }
  }

  internalRender() {
    var resultInternal = null;
    var resultStyle = null;
    let componentRef = this;
    var currentStep = this.props.currentStepOrderId;
    var currentStepId = this.props.stepData[currentStep].id;
    this.storedStepId = currentStep;
    fetch(this.props.stepData[currentStep].stim_server_url).then(function(response) {
      componentRef.resourceFetched = true;
      if (response.ok) {
        return response.json();
      } else {
        // TODO: handle error
        throw response.status + " " + response.statusText;
      }
    }).then(function(response) {
      var parser = new DOMParser();
      var doc = parser.parseFromString(response.final_html, "text/html");
      resultInternal = doc.body.children[0].src;
      resultStyle = doc.body.children[0].getAttribute("ls-style");
      if (doc.body.children[0].tagName.toLowerCase() === "img") {
        componentRef.setState((state) => {
          return {
            stimSrc: resultInternal,
            stimStyle: resultStyle,
            stimHtmlConstructorType: "img"
          }
        })
      } else {
        componentRef.setState((state) => {
          return {
            stimSrc: resultInternal,
            stimStyle: resultStyle,
            stimHtmlConstructorType: "video"
          }
        })
      }
      if (componentRef.props.stepData[currentStep].time_to_show_next_button_ms >= 0 && componentRef.props.stepData[currentStep].time_to_move_next_button_ms >= 0) {
        //progressTimer(componentRef.props.progressAvailableTrue, componentRef.props.stepData, currentStep);
      } else if (doc.body.children[0].tagName.toLowerCase() === "img" && componentRef.props.stepData[currentStep].time_to_move_next_button_ms < 0) {
        //progressTimer(componentRef.props.progressAvailableTrue, componentRef.props.stepData, currentStep, 10000);
      }
      //componentRef.props.et.startTracking(componentRef.props.studyReducer.studyId, componentRef.props.studyReducer.participant, ["lumenStim"], componentRef.handleError, currentStepId, componentRef.props.studyReducer.showGaze);
    }).catch(function(error) {
      if (!!error.errorMessage) {
        ROLLBAR.error(error.errorMessage)
      } else if (typeof error === 'object') {
        ROLLBAR.error(error.message)
      } else {
        ROLLBAR.error(error)
      }
      componentRef.props.errorState();
    })
  }

  componentDidMount() {
    this.internalRender();
  }

  handleEnded(state, prevState) {
    if ((state.currentTime == state.duration) && state.paused && this.endHandled === false) {
      this.endHandled = true;
      setTimeout(StudyNextController.callNext, 1000);
    }
  }

  componentDidUpdate() {
    if (this.props.currentStepOrderId !== this.storedStepId) {
      this.internalRender();
    }
    setTimeout(() => {
      if (typeof this.player !== 'undefined' && this.playerSubscribed !== true) {
        this.playerSubscribed = true;
        var currentStep = this.props.currentStepOrderId;
        if (this.props.stepData[currentStep].time_to_show_next_button_ms < 0 || this.props.stepData[currentStep].time_to_move_next_button_ms < 0) {
          this.player.subscribeToStateChange(this.handleEnded.bind(this));
        }
      }
    }, 1000)
  }

  render() {
    return (
      this.htmlConstructor()
    );
  }
}
