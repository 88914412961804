import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button';
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';
import { progressAvailableTrue, progressAvailableFalse, errorState, progressState } from '../../reducers/actions'
import { incrementStep } from '../../components/lumenController/actions'
import PanelistActionController from '../../components/util/panelistActionController';
import LSIntlFormat from '../../components/util/intlFormat.jsx';
import getDeviceInfo from '../../components/util/deviceValidation'

/**
 * Setup template.
 */
export default class SetupContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      instructionText: "",
      instructionTextId: ""
    };

    this.headPositionElementRef = React.createRef();
    this.calibrationElementRef = React.createRef();
    this.validationElementRef = React.createRef();
    this.instructionsText = React.createRef();
    this.retryButton = React.createRef();

    this.checkCameraReady = this.checkCameraReady.bind(this);
    this.headPosition = this.headPosition.bind(this);
    this.calibration = this.calibration.bind(this);
    this.validate = this.validate.bind(this);
    this.setupFinishCallback = this.setupFinishCallback.bind(this);
    this.checkOrientation = this.checkOrientation.bind(this);
    this.initCB = this.initCB.bind(this);
    this.retryRefresh = this.retryRefresh.bind(this);
  }

  setupFinishCallback(userId, success) {
    if (!success) {
      this.setState((state) => {
        return { instructionText: "Sorry, something went wrong. Use the button below to retry.", instructionTextId: "app.setup.retry" };
      });
      this.instructionsText.current.style.display = "block";
      this.retryButton.current.style.display = "inline-block";
    } else {
      this.setState((state) => {
        return { instructionText: "Success! Please keep your head in the same position whilst viewing the next page.", instructionTextId: "app.setup.instruction5" };
      });
      this.instructionsText.current.style.display = "block";
      setTimeout(() => {
        // this.instructionsText.current.style.display = "none";
        this.props.store.dispatch(progressState());
      }, 2000)
      //this.props.store.dispatch(progressAvailableTrue());
    }
  }

  /**
   * Currently unused in setup.
   */
  validate(userId, success) {
    if (!success) {
      this.props.store.dispatch(errorState({errorMessage: "Unable to calibrate.", errorMessageId: "app.error.calibrate"}));
      return
    }
    this.setState((state) => {
      return { instructionText: "Now we just need to validate.", instructionTextId: "app.setup.instruction4" };
    });
    this.instructionsText.current.style.display = "block";
    setTimeout(() => {
      // this.instructionsText.current.style.display = "none";
      this.props.studyReducer.et.validate(this.props.studyReducer.studyId, this.props.studyReducer.participant, this.validationElementRef.current, this.setupFinishCallback, 120000, this.props.studyReducer.cellId);
    }, 3000);
  }

  calibration(userId, success) {
    if (!success) {
      this.props.store.dispatch(errorState({errorMessage: "Unable to determine your head position.", errorMessageId: "app.error.headPosition"}));
      return
    }
    this.setState((state) => {
      return { instructionText: "Time to calibrate!", instructionTextId: "app.setup.instruction3" };
    });
    this.instructionsText.current.style.display = "block";
    let instructionObj = {
      top: LSIntlFormat.IntlMessageFormat("app.setup.calibrationProp.watch", "Watch this dot").toUpperCase(),
      bottom: getDeviceInfo().isMobile ? LSIntlFormat.IntlMessageFormat("app.setup.calibrationProp.tap", "Tap anywhere to continue").toUpperCase()
                         : LSIntlFormat.IntlMessageFormat("app.setup.calibrationProp.click", "Click anywhere to continue").toUpperCase()
    }
    setTimeout(() => {
      this.instructionsText.current.style.display = "none";
      this.props.studyReducer.et.calibrate(this.props.studyReducer.studyId, this.props.studyReducer.participant, this.calibrationElementRef.current, this.setupFinishCallback, instructionObj.top, instructionObj.bottom, 120000);
    }, 3000);
  }

  headPosition(userId, success) {
    if (!success) {
      this.props.store.dispatch(errorState({errorMessage: "Unable to find camera!", errorMessageId: "app.error.findCamera"}));
      return
    }
    this.setState((state) => {
      return { instructionText: "We are now detecting your face. Please position your head in the center of the box.", instructionTextId: "app.setup.instruction2" };
    });
    let instructionObj = {
      bottom: getDeviceInfo().isMobile ? LSIntlFormat.IntlMessageFormat("app.setup.calibrationProp.tap", "Tap anywhere to continue").toUpperCase()
                         : LSIntlFormat.IntlMessageFormat("app.setup.calibrationProp.click", "Click anywhere to continue").toUpperCase()
    }
    setTimeout(() => {
      //this.instructionsText.current.style.display = "none";
      this.props.studyReducer.et.isHeadPositionReady(this.props.studyReducer.studyId,this.props.studyReducer.participant, this.headPositionElementRef.current, this.calibration, instructionObj.bottom, 30000)
    }, 3000);
  }

  checkCameraReady(userId, success) {
    if (!success) {
      this.props.store.dispatch(errorState({errorMessage: "Unable to initialise!", errorMessageId: "app.error.initialise"}));
      return
    }
    this.setState((state) => {
      return { instructionText: "Initialising...", instructionTextId: "app.setup.instruction1" };
    });
    this.props.studyReducer.et.isCameraReady(this.props.studyReducer.studyId, this.props.studyReducer.participant, this.headPosition, 20000);
  }

  initCB(success) {
    if (!success) {
      this.props.store.dispatch(errorState());
      return
    }
    this.props.studyReducer.et.init(this.props.studyReducer.studyId, this.props.studyReducer.participant, this.props.studyReducer.panelCompanyCode, this.checkCameraReady, this.props.studyReducer.brokerDetails.url, this.props.studyReducer.sessionId, this.props.studyReducer.cellId)
  }

  checkOrientation(callback) {
    // TODO: REENABLE LATER
    /*
    if (window.innerWidth <= 767) {
      if (window.matchMedia("(orientation: landscape)").matches) {
        this.setState((state) => {
          return { instructionText: "Please rotate your device to portrait mode.", instructionTextId: "app.setup.instruction0" };
        });
        var timeoutCounter = 0;
        var timeout = 30000;
        const checkOrientationLoop = () => {
          if (window.matchMedia("(orientation: portrait)").matches) {
            callback(true);
          } else if (timeoutCounter > (timeout / 30)) {
            callback(false);
          } else {
            timeoutCounter++
            setTimeout(checkOrientationLoop, 30);
          }
        }
        setTimeout(checkOrientationLoop, 30);
      } else {
        callback(true)
      }
    } else {
      callback(true)
    }
    */

    callback(true)
  }

  retryRefresh() {
    window.location.href = window.location.origin + window.location.pathname + "?participant=" + this.props.studyReducer.participant;
  }

  componentDidMount() {
    this.props.store.dispatch(progressAvailableFalse());
    this.checkOrientation(this.initCB)
  }

  render() {
    return (<Container className="setupCont">
      <Row>
        <Col xs={12}>
          <div className="ls-main">
            <h1>Setup</h1>
            <p className="instructionsText" ref={this.instructionsText}>
              { !!this.state.instructionTextId ? <FormattedMessage id={this.state.instructionTextId} defaultMessage={this.state.instructionText} description="Setup Instructions"/> : null }
            </p>
            <Button ref={this.retryButton} style={{marginTop: "20px", display: "none"}} onClick={this.retryRefresh} className="button-primary">
              <FormattedMessage id="app.setup.retryButton" defaultMessage="Retry" description="Retry Button"/>
            </Button>
          </div>
        </Col>
      </Row>
      <div id="headPositionElement" ref={this.headPositionElementRef}></div>
      <div id="calibrationElement" ref={this.calibrationElementRef}></div>
      <div id="validationElement" ref={this.validationElementRef}></div>
    </Container>);
  }
}
