import React from 'react';
import { connect } from 'react-redux';
import LSTemplateRenderer from '../templateRenderer'

function LSTemplateRendererCont(props) {
  return (
    <LSTemplateRenderer
      errorMessage={props.errorMessage}
      errorMessageId={props.errorMessageId}
      successRedirectUrl={props.successRedirectUrl}
      redirectUrlMemberInsertParameter={props.redirectUrlMemberInsertParameter}
      participant={props.participant}
      accessCode={props.accessCode}
      studyId={props.studyId}
      participantId={props.participantId}
      basestate={props.basestate}
      screenoutRedirectUrl={props.screenoutRedirectUrl}
      quotaFullRedirectUrl={props.quotaFullRedirectUrl}
      studyReducer={props.studyReducer} />
  )
}

const mapStateToProps = function(state) {
  return {
    basestate: state.baseReducer.basestate,
    errorMessage: state.baseReducer.errorMessage,
    errorMessageId: state.baseReducer.errorMessageId,
    successRedirectUrl: state.studyReducer.successRedirectUrl,
    screenoutRedirectUrl: state.studyReducer.screenoutRedirectUrl,
    redirectUrlMemberInsertParameter: state.studyReducer.redirectUrlMemberInsertParameter,
    accessCode: state.studyReducer.accessCode,
    participant: state.studyReducer.participant,
    participantId: state.studyReducer.participantId,
    studyId: state.studyReducer.studyId,
    quotaFullRedirectUrl: state.studyReducer.quotaFullRedirectUrl,
    studyReducer: state.studyReducer
  }
}

export default connect(mapStateToProps)(LSTemplateRendererCont);
