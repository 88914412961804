import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';
import { progressAvailableFalse } from '../../reducers/actions';
import {persistor} from "../../store/index";
import PanelistActionController from '../../components/util/panelistActionController';
import ROLLBAR from '../../rollbar.js'

/**
 * Error template.
 */
export default class ErrorContent extends React.Component {
  constructor(props) {
    super(props);
  }

  redirectHandler() {
    persistor.purge()
    var modSuccessRedirectUrl = this.props.quotaFullRedirectUrl;
    window.location.href = modSuccessRedirectUrl.replace(this.props.redirectUrlMemberInsertParameter, this.props.participant);
  }

  componentDidMount() {
    this.props.store.dispatch(progressAvailableFalse());
  }

  // TODO: Convert into error token system WITH TRANSLATIONS
  render() {
    return (<Container>
      <Row>
        <Col xs={12}>
          <div className="ls-main">
            <h1>Error</h1>
            <p>
              {/* <FormattedMessage id={this.props.errorMessageId} defaultMessage={this.props.errorMessage} description="Error message"/><br></br> */}
              {this.props.errorMessage}<br></br>
              {!!this.props.quotaFullRedirectUrl && this.props.errorMessage.includes("Quota") ? <FormattedMessage id="app.generic.redirect" defaultMessage="You will be redirected in 3 seconds." description="Redirect message"/> : null}
            </p>
          </div>
        </Col>
      </Row>
    </Container>);
  }
}
