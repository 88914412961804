export function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}

export function detectQueryString(currentUrl) {
    // regex pattern for detecting ? character
    var pattern = new RegExp(/\?+/g);

    return pattern.test(currentUrl);
}
