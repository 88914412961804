import React from 'react';
import Button from 'react-bootstrap/Button';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import LSFaqModalCont from '../modal/containers/faqModal'
import LSTermsModalCont from '../modal/containers/termsModal'
import LSContactModalCont from '../modal/containers/contactModal'
import LSTakePartModalCont from '../modal/containers/takePartModal'
import LSLanguageModalCont from '../modal/containers/languageModal'
import {FormattedMessage} from 'react-intl';
import {persistor} from "../../store/index";

// add later
// <Button onClick={() => this.props.toggleHelpModal()} className="mobileOnly"><FormattedMessage id="app.button.help" defaultMessage="Help" description="Help Button"/></Button>

/**
 * Inner menu component. This contains the list of menu items.
 */
class LSMenuInner extends React.Component {
  render() {
    return (<div className="menu-cont">
      <Button onClick={() => this.props.toggleFaqModal()}><FormattedMessage id="app.button.faqs" defaultMessage="Privacy Policy / FAQs" description="Privacy Policy / FAQ Button"/></Button>
      <Button onClick={() => this.props.toggleTermsModal()}><FormattedMessage id="app.button.terms" defaultMessage="Terms of Use" description="Terms of Use Button"/></Button>
      <Button onClick={() => this.props.toggleLanguageModal()}><FormattedMessage id="app.button.selectLanguage" defaultMessage="Select Language" description="Select Language Button"/></Button>
      <Button onClick={() => this.props.toggleContactModal()}><FormattedMessage id="app.button.feedback" defaultMessage="Feedback" description="Feedback Button"/></Button>
      { process.env.REACT_APP_LUMEN_ENV_VALUE !== 'release' ? <Button onClick={() => persistor.purge()}>DEBUG - CLEAR STORE</Button> : null }
      { process.env.REACT_APP_LUMEN_ENV_VALUE !== 'release' ? <Button onClick={() => this.props.debugState()}>DEBUG - SHOW DEBUG STATE</Button> : null }
    </div>);
  }
}

/**
 * Outer menu component. This is used to animate the opening of the menu.
 */
export default class LSMenu extends React.Component {
  constructor(props) {
    super(props);

    this.menuContToggle = this.menuContToggle.bind(this);
  }
  menuContToggle() {
    this.props.toggleMenu()
  }
  render() {
    return (<div className={this.props.className}>
      <LSFaqModalCont/>
      <LSContactModalCont/>
      <LSLanguageModalCont/>
      <LSTakePartModalCont/>
      <LSTermsModalCont />
      <i onClick={this.menuContToggle} className="fas fa-bars menu"></i>
      <CSSTransitionGroup transitionName="toggle-fade" transitionEnterTimeout={300} transitionLeaveTimeout={300}>
        {
          this.props.displayMenu
            ? <LSMenuInner debugState={this.props.debugState} togglePrivacyModal={this.props.togglePrivacyModal} toggleHelpModal={this.props.toggleHelpModal} displayFaqModal={this.props.displayFaqModal} displayContactModal={this.props.displayContactModal} displayLanguageModal={this.props.displayLanguageModal} toggleFaqModal={this.props.toggleFaqModal} toggleTermsModal={this.props.toggleTermsModal} toggleContactModal={this.props.toggleContactModal} toggleLanguageModal={this.props.toggleLanguageModal}/>
            : null
        }
      </CSSTransitionGroup>
    </div>);
  }
}
