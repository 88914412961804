import React from 'react';
import {connect} from 'react-redux';
import LSModal from '../modal'
import {toggleFaqModal} from '../actions'
import {FormattedMessage} from 'react-intl';

const modalContent = () => {
  return <React.Fragment>
    <p><b><FormattedMessage id="app.faq.question1" defaultMessage="Q: What is ViewPoints and what does taking part in an eye tracking survey involve?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer1" defaultMessage="A: This survey involves the use of eye tracking technology. You will be asked to sit in front of your device’s webcam or front-facing camera and perform a calibration so that an estimate can be made of where you are looking on your screen. During the survey you will be asked to view some items (eg images, websites or videos) whilst the survey tool estimates where you are looking. The data that is captured from this ‘eye tracking’ are the coordinates of the estimated gaze points on your screen over time. The software uses images of you from your webcam to make these estimates but does not capture photos or videos of you. You may also be asked some questions about yourself, your experience and the items you have seen during the survey. The data is sent to Lumen Research Ltd. Lumen Research will analyse the data and share results with its clients." description="FAQ Content"/></p>
    <p><b><FormattedMessage id="app.faq.question2" defaultMessage="Q: Why does the software require a webcam / camera and how is this used?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer2" defaultMessage="A: The software uses images from your webcam or device’s camera to estimate where you are looking on a screen. It does this using a combination of facial tracking and eye tracking software that analyses images locally on your machine in real time. These images are processed and shown to you during calibration so that you can correctly position yourself but the images are not recorded so your privacy is protected. The data that is recorded and collected from the eye tracking is not images but numeric data about estimated gaze points on the stimulus over time." description="FAQ Content"/></p>
    <p><b><FormattedMessage id="app.faq.question3" defaultMessage="Q: Do you take images of me whilst doing the eye tracking?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer3" defaultMessage="A: The only time images are used is to allow you to correctly position yourself during calibration. These images are not saved, and only seen by you. The data that is recorded and collected from the eye tracking is not images but numerical data about estimated gaze points on the stimulus over time." description="FAQ Content"/></p>
    <p><b><FormattedMessage id="app.faq.question4" defaultMessage="Q: What data is collected during the survey?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer4.line1" defaultMessage="A: The software will collect some or all of the following five types of data:" description="FAQ Content"/></p>
    <ul>
      <li><FormattedMessage id="app.faq.answer4.line2" defaultMessage="“User ID” - The User ID used to identify respondents (for the purpose of administering participation rewards);" description="FAQ Content"/></li>
      <li><FormattedMessage id="app.faq.answer4.line3" defaultMessage="“Gaze data” – Estimates of where users are looking on their screen when shown stimuli on the screen" description="FAQ Content"/></li>
      <li><FormattedMessage id="app.faq.answer4.line4" defaultMessage="“Survey data” – Responses to questions answered by users" description="FAQ Content"/></li>
      <li><FormattedMessage id="app.faq.answer4.line5" defaultMessage="“Metadata” - Data about the time at which individuals successfully participated in the Research." description="FAQ Content"/></li>
      <li><FormattedMessage id="app.faq.answer4.line6" defaultMessage="“Physical Device data” – Data about the computer on which a user participates such as the level of RAM, the type of CPU in the machine and the user agent (operating system and browser version) as well as information about the computer’s screen (size and resolution), webcam (make and resolution), antivirus program, and the computer’s IP address." description="FAQ Content"/></li>
    </ul>
    <p><b><FormattedMessage id="app.faq.question5" defaultMessage="Q: How will the data collected be used?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer5.line1" defaultMessage="A: The data collected will be used to explore how individuals view and interact with various types of visual images and media and to gauge people’s opinions of the images they are shown." description="FAQ Content"/></p>
    <p><FormattedMessage id="app.faq.answer5.line2" defaultMessage="Lumen may share the research data with affiliated companies and with business partners and clients. While the research data might be shared with affiliates, partners and clients, this will typically be on an anonymous basis. Such affiliates, partners and clients may use this data to create summary descriptions of a wide range of consumer behaviours and consumer segments to develop products or to provide marketing solutions to their customers or consumers." description="FAQ Content"/></p>
    <p><FormattedMessage id="app.faq.answer5.line3" defaultMessage="Lumen collect “Physical Device data” and “Metadata” to help manage the collection and processing of data, including quality control and volume management." description="FAQ Content"/></p>
    <p><FormattedMessage id="app.faq.answer5.line4" defaultMessage="The data collected will be retained for the period necessary to fulfill the purposes outlined." description="FAQ Content"/></p>
    <p><b><FormattedMessage id="app.faq.question6" defaultMessage="Q: How secure is my data?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer6" defaultMessage="A: The data collected by Lumen is encrypted using SSL technology. This is the most commonly used high standard of encryption technology and is used by banks to secure banking transactions and other highly sensitive communications. It is also used by Amazon, PayPal, eBay, and other websites requiring bank-level security systems. Scientists estimate that breaking 256 bit encryption using current technology would require substantially more time than the age of the universe. Collected data is stored on secure web servers managed by Amazon Web Services (AWS). AWS is one of the most commonly used safe and dependable cloud computing services platforms and is used by major cloud-based commercial services including Netflix and Expedia." description="FAQ Content"/></p>
    <p><b><FormattedMessage id="app.faq.question7" defaultMessage="Q: What obligations do you have regarding the Content you are shown?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer7" defaultMessage="A: By participating, you also understand and agree that the Content you are shown in this study may be confidential, the property of a third party and/or subject to trademark or copyright laws and that you will not make screenshots, record or otherwise copy any of the Content or redistribute any of the Content of the study in any way." description="FAQ Content"/></p>
    <p><b><FormattedMessage id="app.faq.question8" defaultMessage="Q: I'm not sure how good my webcam is, will it work for eye tracking?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer8" defaultMessage="A: The system works with a large range of webcams – it should not be a problem." description="FAQ Content"/></p>
    <p><b><FormattedMessage id="app.faq.question9" defaultMessage="Q: I've completed the study, do I need to do anything else?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer9" defaultMessage="A: No it is not necessary to do anything else. The software will close down automatically. You can now uninstall the software and delete the downloaded file." description="FAQ Content"/></p>
    <p><b><FormattedMessage id="app.faq.question10" defaultMessage="Q: Do I need to be connected to the internet to participate?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer10" defaultMessage="A: Yes, you will need an internet connection to download the program and to complete the task." description="FAQ Content"/></p>
    <p><b><FormattedMessage id="app.faq.question11" defaultMessage="Q: Do I need to complete the task on a stable surface?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer11" defaultMessage="A: Yes, for the best results, you should sit comfortably in front of your computer screen and, after the calibration, keep your head still whilst you are presented with the stimulus, until notified. We advise not taking part if you are on a train or balancing your laptop on your lap etc." description="FAQ Content"/></p>
    <p><b><FormattedMessage id="app.faq.question12" defaultMessage="Q: The FAQs haven't solved my problem, what do I do now?" description="FAQ Content"/></b></p>
    <p><FormattedMessage id="app.faq.answer12" defaultMessage="A: Please email us on ViewPoints@lumen-research.com and we'll get back to you as quickly as we can." description="FAQ Content"/></p>
    <p><FormattedMessage id="app.faq.patent" defaultMessage="Lumen's testing of web content is powered by our own proprietary in-context testing technology (UK Patent Application Number 1816158.8)" description="FAQ Content"/></p>
  </React.Fragment>
}

function LSFaqModalCont(props) {
  return (
    props.displayFaqModal
    ? <LSModal title="Privacy Policy / FAQs" titleId="app.button.faqs" content={modalContent()} modalToggle={props.toggleFaqModal}/>
    : null)
}

const mapStateToProps = function(state) {
  return {displayFaqModal: state.modalReducer.displayFaqModal}
}

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    toggleFaqModal: () => dispatch(toggleFaqModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LSFaqModalCont);
