import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import webcam from './images/webcam.png';
import laptopWebcam from './images/laptopWebcam.png';
import ipad from './images/ipad.png';
import {FormattedMessage} from 'react-intl';
import {progressAvailableTrue} from '../../reducers/actions';
import {persistor} from "../../store/index";
import getDeviceInfo from '../../components/util/deviceValidation'

/**
 * Index template.
 */
export default class IndexContent extends React.Component {
  constructor(props) {
    super(props);

    this.welcomeRender = this.welcomeRender.bind(this);
  }

  componentDidMount() {
    this.props.store.dispatch(progressAvailableTrue());
  }

  welcomeRender() {
    let indexImageStyle = {
      maxWidth: "calc(100% / 3)"
    }
    if (getDeviceInfo().os.family === "iOS" && (typeof navigator.mediaDevices === "undefined")) {
      persistor.purge();
      window.addEventListener('beforeunload', persistor.purge);
      return (<Row>
        <p><FormattedMessage id="app.index.webview" defaultMessage="Sorry, it appears you have accessed our page from inside a webview. Please open this link inside Safari." description="Start page webview"/></p>
      </Row>)
    } else if (!!this.props.studyId) {
      return (<React.Fragment>
        <Row>
          <Col xs={12}>
            <p><FormattedMessage id="demo.index.content.line1" defaultMessage="During this demo we will use your camera to estimate where you are looking. Don't worry, we won't take any photos or videos." description="Greeting and instructions"/></p>
          </Col>
        </Row>
        <Row style={{marginTop: "10px", marginBottom: "20px"}}>
          <Col xs={12}>
            { getDeviceInfo().isMobile ? <img src={ipad} style={indexImageStyle} /> : <React.Fragment><img src={webcam} style={indexImageStyle} /><img src={laptopWebcam} style={indexImageStyle} /></React.Fragment> }
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p><FormattedMessage id="app.index.content.line2" defaultMessage="Does your device have a camera? If so, please press the start button below." description="Greeting and instructions"/></p>
          </Col>
        </Row>
      </React.Fragment>)
    } else {
      persistor.purge();
      window.addEventListener('beforeunload', persistor.purge);
      return (<Row>
        <p><FormattedMessage id="app.index.rejection" defaultMessage="Sorry, it appears you have followed a malformed link." description="Start page malformed link"/></p>
      </Row>)
    }
  }

  render() {
    return (<Container>
      <Row>
        <Col xs={12}>
          <div className="ls-main">
            <Row>
              <Col xs={12}>
                <h1><FormattedMessage id="app.index.title" defaultMessage="Welcome" description="Welcome"/></h1>
              </Col>
            </Row>
            {this.welcomeRender()}
          </div>
        </Col>
      </Row>
    </Container>);
  }
}
