import getDeviceInfo from './deviceValidation';
import ROLLBAR from '../../rollbar';

// TODO: refactor into single function?

function handleFullscreenOpen(fsRequired) {
  if (fsRequired && !getDeviceInfo().os.family.includes("iOS")) {
    if (getDeviceInfo().name == "Safari") {
      document.documentElement.webkitRequestFullScreen();
    } else {
      document.documentElement.requestFullscreen().catch(function(e) {
        // console.log("Couldn't open fullscreen!");
        ROLLBAR.info("Couldn't open fullscreen:", e);
      })
    }
  }
}

function handleFullscreenClose(fsRequired) {
  if (fsRequired && !getDeviceInfo().os.family.includes("iOS")) {
    if (getDeviceInfo().name == "Safari") {
      document.webkitExitFullscreen();
    } else {
      document.exitFullscreen().catch((e) => {
        // console.log("Could not exit fullscreen!");
        ROLLBAR.info("Couldn't exit fullscreen:", e);
      })
    }
  }
}

export {handleFullscreenOpen, handleFullscreenClose};
