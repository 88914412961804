import React from 'react';
import {connect} from 'react-redux';
import LSModal from '../modal'
import {toggleTermsModal} from '../actions'
import {FormattedMessage} from 'react-intl';

const modalContent = () => {
  return <React.Fragment>
    <p><FormattedMessage id="app.terms.line1" defaultMessage="These terms of use (“Terms of Use”) govern the agreement between You and Lumen Research Ltd (“Lumen” or “We”), a company registered in England, company number 8682432 with registered office at Unit 215, 22 Highbury Grove, London N5 2EF and data protection registration reference ZA130772, regarding your use of the “ViewPoints” eye tracking software (the “Software”) on your device." description="Terms of Use Content"/></p>
    <p><FormattedMessage id="app.terms.line2" defaultMessage="1. Agreement to terms" description="Terms of Use Content"/></p>
    <p><FormattedMessage id="app.terms.line3" defaultMessage="By using the Software, you acknowledge that you have agreed to be bound by this Agreement. If you do not agree to the terms and conditions of this Agreement, do not complete the ViewPoints survey." description="Terms of Use Content"/></p>
    <p><FormattedMessage id="app.terms.line4" defaultMessage="2. Confidential Information" description="Terms of Use Content"/></p>
    <p><FormattedMessage id="app.terms.line5" defaultMessage="When installing, using or in any way engaging with ViewPoints, you may have access to information and/or content belonging or pertaining to Lumen including information which is identified or treated by Lumen or any of Lumen’s clients or customers as confidential or which by reason of its character or the circumstances or manner of its disclosure is evidently confidential including (without prejudice to the foregoing generality) any information about business plans, maturing new business opportunities, research and development projects, designs, secret processes, codes, software designs, trade secrets, product or services development and formulae, know-how, inventions, sales statistics and forecasts, marketing strategies and plans, costs, profit and loss and other financial information (save to the extent published in audited accounts), prices and discount structures (all whether or not recorded in writing or in electronic or other format) (“Confidential Information”). Such Confidential Information shall remain the sole and exclusive property of Lumen and/or its customers, and in no event shall you obtain or receive any right, title and/or interest in such Confidential Information." description="Terms of Use Content"/></p>
    <p><FormattedMessage id="app.terms.line6" defaultMessage="You must not either during your participation in ViewPoints or at any time (without limit) after the termination of such participation: divulge or communicate to any person; use for your own purposes; or through any failure to exercise due care and diligence, cause any unauthorised disclosure of any Confidential Information. You must at all times use your best endeavours to prevent publication or disclosure of any Confidential Information. These restrictions shall cease to apply to any information which shall become available to the public generally otherwise than through your default." description="Terms of Use Content"/></p>
    <p><FormattedMessage id="app.terms.line7" defaultMessage="3. Lumen Property" description="Terms of Use Content"/></p>
    <p><FormattedMessage id="app.terms.line8" defaultMessage="The ViewPoints Software and all data, codes, keys, passwords, designs, drawings, records and other documents or materials relating to the Software shall be and remain the property of Lumen throughout the duration of your participation in a ViewPoints survey. Lumen owns all intellectual or proprietary rights in any and all existing and future intellectual or industrial property rights in and to any documents, materials, models, designs, drawings, processes, inventions, formulae, computer coding, data, system interfaces, methodologies, know-how, Confidential Information or other work, performed made, created, devised, developed, enhanced, deconstructed or discovered by you either alone or with any other person in connection with or relating to the Software and/or Device or capable of being used or adapted for use therein or in connection therewith (whether registered or unregistered), including all existing and future patents, copyrights, design rights, database rights, trade marks, semiconductor topography rights, plant varieties rights, internet rights/domain names, know-how and any and all applications for any of the foregoing and any and all rights to apply for any of the foregoing (“Proprietary Rights”). No Proprietary Rights shall pass to you at any time." description="Terms of Use Content"/></p>
    <p><FormattedMessage id="app.terms.line9" defaultMessage="4. Terms of and restrictions on use" description="Terms of Use Content"/></p>
    <p><FormattedMessage id="app.terms.line10" defaultMessage="The ViewPoints Software is licensed to end users. Subject to this Agreement, we hereby agree to permit you, on a non-exclusive, revocable, non-transferable, non-sublicensable, limited basis, to use the ViewPoints Software on a device that you own or control or a personal computer with respect to which you represent and warrant that you have all necessary rights and permissions for such installation. For the avoidance of doubt, you may not use for any purpose outside of ViewPoints, translate, copy, reproduce, transmit by telecommunications, adapt, merge, change or modify the Software in any way." description="Terms of Use Content"/></p>
    <p><FormattedMessage id="app.terms.line11" defaultMessage="You agree not to: (i) copy, modify, adapt, make available, rent, lease, timeshare, create a derivative work of, or attempt to sell or otherwise transfer any right in the Software (ii) disassemble, decompile, or reverse engineer any parts of the Software by any means; nor permit or assist any party to derive or attempt to derive the source code of, disassemble, decrypt, decompile or reverse engineer the Software; nor take any other steps in order to derive design information regarding the Software; (iii) use the Software for any purpose other than the purpose it was originally distributed to you for; (iv) use spiders, robots or other automated data mining techniques to catalogue, download, store, or otherwise reproduce or distribute the Software, or to manipulate the results of any survey or any other activity; (v) take any action to interfere with the Software or an individual’s use of the Software including, without limitation, by overloading, “flooding”, “mail bombing” or “crashing” the Software and the data storage system the Software uses through Amazon Web Services (vi) send, to us any illegal, deceptive, or harmful code, including, without limitation, any virus, spyware, adware, or any other harmful code." description="Terms of Use Content"/></p>
    <p><FormattedMessage id="app.terms.line12" defaultMessage="All rights not expressly granted to you hereunder are reserved to us and our licensors. You agree that we have no obligation under this Agreement or otherwise to correct any bugs, defects or errors in the Software, or to otherwise support, maintain, improve, modify, upgrade, update or enhance the Software. We will not be responsible for providing assistance to you or your designees in the use of the Software in any manner." description="Terms of Use Content"/></p>
    <p><FormattedMessage id="app.terms.line13" defaultMessage="We are not liable for any damage to your P.C. including but not limited to as a result of your use of the Software. We are not liable for any additional data charges incurred by you as a result of the data collected by the Software through your participation in the Research." description="Terms of Use Content"/></p>
    <p><FormattedMessage id="app.terms.line14" defaultMessage="5. Disclaimer" description="Terms of Use Content"/></p>
    <p><FormattedMessage id="app.terms.line15" defaultMessage="We and our respective directors, officers, employees, agents, representatives, licensors and providers make no representation or warranty or condition (a)that the software will be available on a timely basis, or that access to the software will be uninterrupted or secure; (b)that defects or errors will be corrected; or (c)that the software or the servers or networks through which the software and the device are made available are secure or free of viruses or other harmful components. We shall have no obligation to furnish any maintenance and/or support services with respect to the software." description="Terms of Use Content"/></p>
    <p><FormattedMessage id="app.terms.line16" defaultMessage="6. Limitation of liability" description="Terms of Use Content"/></p>
    <p><FormattedMessage id="app.terms.line17" defaultMessage="(a) To the fullest extent permissible under applicable law, you agree that we and our respective directors, officers, employees, agents, representatives, licensors and providers shall have no liability for any or all losses, damages or costs of any kind resulting from your installation, use or access of the software, your use of or inability to use the software, any information or materials made available on or through the software and/or as otherwise relating to the software or otherwise to this agreement, regardless of the nature of the legal, equitable or statutory right claimed to have been violated." description="Terms of Use Content"/></p>
    <p><FormattedMessage id="app.terms.line18" defaultMessage="(b) To the fullest extent permissible under applicable law, your sole and exclusive remedy for dissatisfaction with the software is to uninstall and stop using it." description="Terms of Use Content"/></p>
    <p><FormattedMessage id="app.terms.line19" defaultMessage="(c) In no event shall any of our entities, directors, officers, employees, agents, representatives be liable to you for any incidental, indirect, consequential, statutory, exemplary, punitive or special damages, loss of revenue, loss of opportunity, loss of reputation, loss of use, loss of data, loss of other intangibles, or unauthorized interception of data collected by the software by third parties, whether foreseeable or not, however caused, and under any contract, tort (including without limitation negligence), strict liability or other theory, even if advised in advance of the possibility that such damages might arise." description="Terms of Use Content"/></p>
  </React.Fragment>
}

function LSTermsModalCont(props) {
  return (
    props.displayTermsModal
    ? <LSModal title="Terms of Use" titleId="app.button.terms" content={modalContent()} modalToggle={props.toggleTermsModal}/>
    : null)
}

const mapStateToProps = function(state) {
  return {displayTermsModal: state.modalReducer.displayTermsModal}
}

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    toggleTermsModal: () => dispatch(toggleTermsModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LSTermsModalCont);
