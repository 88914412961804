import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar'

/**
 * This is the footer component. Controls the users ability to continue the study.
 */
export default class LSProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentProgress: 0
    }

    this.updateProgress = this.updateProgress.bind(this);
  }

  updateProgress(incrementAmount) {
    this.setState((state) => {
      return {currentProgress: state.currentProgress + incrementAmount}
    })
  }

  componentDidMount() {
    let componentRef = this;
    let incrementAmount = (100 / (this.props.progressBarTiming - 600)) * 50;
    let progressInterval = setInterval(function() {
      componentRef.updateProgress(incrementAmount);
      if (componentRef.state.currentProgress >= 100) {
        clearInterval(progressInterval)
        setTimeout(componentRef.props.progressBarFinished, 600)
      }
    }, 50)
  }

  render() {
    return (<ProgressBar now={this.state.currentProgress} />);
  }
}
