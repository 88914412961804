import React from 'react';
import ReactMarkdown from 'react-markdown';
import { shuffleArray } from '../util/util';
import { store } from "../../store/index";

function radioQuestion(question, questionid, index, currentQIndex, demographics) {
  let answersSorted = question.answers.sort((a, b) => (a.position > b.position) ? 1 : -1);
  let answerRenderArray = [];
  answersSorted.map(function(item, i){
    answerRenderArray.push(
      <div className="radioAnswerCont" key={i}>
        <input name={questionid} id={"q"+questionid+"a"+i} type="radio" value={demographics ? item.answer_code || item.id : item.id} />
        <label className="radioLabel" htmlFor={"q"+questionid+"a"+i}>{item.text}</label><br />
      </div>
    )
  })
  if (question.random_order_answers) {
    shuffleArray(answerRenderArray);
  }
  return (
    <div className={"questionCont questionCont" + index + (index === currentQIndex ? " active" : "")} key={questionid}>
      <div className="centerAlign">
        <ReactMarkdown source={question.text} />
      </div>
      <div>
        {answerRenderArray.map(function(item, i){
          return(item)
        })}
      </div>
    </div>
  )
}

function checkboxQuestion(question, questionid, index, currentQIndex, demographics) {
  let answersSorted = question.answers.sort((a, b) => (a.position > b.position) ? 1 : -1);
  let handleChange= (e, questionId, exclusive) => {
    if (exclusive) {
      let checkboxes = document.querySelectorAll("input[name='" + questionId + "']");
      checkboxes = Array.from(checkboxes);
      let handledCheckboxes = checkboxes.filter(element => element.id != e.target.id);
      handledCheckboxes.forEach(checkbox => checkbox.checked = false);
    } else {
      let exclusiveCheckboxes = document.querySelectorAll("input[data-exclusive='true'][name='" + questionId + "']");
      exclusiveCheckboxes.forEach(checkbox => checkbox.checked = false);
    }
  }
  let answerRenderArray = [];
  answersSorted.map(function(item, i){
    answerRenderArray.push(
      <div className="checkboxAnswerCont" key={i}>
        <input data-exclusive={item.exclusive} onChange={(e) => {handleChange(e, questionid, item.exclusive)}} name={questionid} id={"q"+questionid+"a"+i} type="checkbox" value={demographics ? item.answer_code || item.id : item.id} />
        <label className="radioLabel" htmlFor={"q"+questionid+"a"+i}>{item.text}</label><br />
      </div>
    )
  })
  let exclusiveAnswerIndex = [];
  let tempAnswerIndex = [];
  answersSorted.forEach((answer, i) => {
    if (answer.exclusive) {
      exclusiveAnswerIndex.push(i);
    }
  })
  exclusiveAnswerIndex.forEach((index) => {
    tempAnswerIndex.push(answerRenderArray[index]);
  })
  exclusiveAnswerIndex.forEach((index) => {
    answerRenderArray.splice(index, 1);
  })
  if (question.random_order_answers) {
    shuffleArray(answerRenderArray);
  }
  tempAnswerIndex.forEach((answer) => {
    answerRenderArray.push(answer);
  })
  return (
    <div className={"questionCont questionCont" + index + (index === currentQIndex ? " active" : "")} key={questionid}>
      <div className="centerAlign">
        <ReactMarkdown source={question.text} />
      </div>
      <div>
        {answerRenderArray.map(function(item, i){
          return(item)
        })}
      </div>
    </div>
  )
}

function textQuestion(question, questionid, index, currentQIndex, checkForCompletionCB) {
  return (
    <div className={"questionCont questionCont" + index + (index === currentQIndex ? " active" : "")} key={questionid}>
      <div className="centerAlign">
        <ReactMarkdown source={question.text} />
      </div>
      <div>
        <div className="textboxAnswerCont">
          <textarea onChange={checkForCompletionCB} name={questionid} id={"q"+questionid+"a0"} /><br />
        </div>
      </div>
    </div>
  )
}

function multiTextQuestion(question, questionid, index, currentQIndex, checkForCompletionCB) {
  let updateSubmittedInput = () => {
    let appendInput = [];
    document.querySelectorAll(".concatInput").forEach((item) => {
      if (item.value != '') {
        appendInput.push(item.value);
      }
    })
    let preparedInput = appendInput.join(";");
    document.querySelector("#q"+questionid+"a0").value = preparedInput;
  }
  return (
    <div className={"questionCont questionCont" + index + (index === currentQIndex ? " active" : "")} key={questionid}>
      <div className="centerAlign">
        <ReactMarkdown source={question.text} />
      </div>
      <div>
        <div className="textboxAnswerCont">
          {question.answers.map((item, i) => {
            return(
              <div key={i}>
                <p style={{marginBottom: 0}}>{i + 1}.</p>
                <input onChange={updateSubmittedInput} className="concatInput" type="text" />
              </div>
            )
          })}
          <input style={{display: "none"}} type="text" onChange={checkForCompletionCB} name={questionid} id={"q"+questionid+"a0"} /><br />
        </div>
      </div>
    </div>
  )
}

function questionTypeSwitch(question, id, index, currentQIndex, checkForCompletionCB, demographics) {
  switch (question.type_id) {
    case 1:
      return radioQuestion(question, id, index, currentQIndex, demographics);
      break;
    case 2:
      return checkboxQuestion(question, id, index, currentQIndex, demographics);
      break;
    case 3:
      return textQuestion(question, id, index, currentQIndex, checkForCompletionCB);
      break;
    case 4:
      // temporary measure
      if (demographics) {
        return textQuestion(question, id, index, currentQIndex, checkForCompletionCB);
        break;
      } else {
        return multiTextQuestion(question, id, index, currentQIndex, checkForCompletionCB);
        break;
      }
    default:
      break;
  }
}

function renderQuestions(questionData, currentQuestionIndex, checkForCompletionCB, demographics = false) {
  return ( <> {
      Array.isArray(questionData) ? questionData.map(function(question, i) {
        return questionTypeSwitch(question, question.id, i, currentQuestionIndex, checkForCompletionCB, demographics)
      }) : questionTypeSwitch(questionData, questionData[0].id, 0, currentQuestionIndex, checkForCompletionCB, demographics)
    } </>
  )
}

export default renderQuestions;
