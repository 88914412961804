import React from 'react';
import {connect} from 'react-redux';
import LSModal from '../modal'
import {toggleLanguageModal, selectLanguage} from '../actions'
import { findFlagUrlByIso2Code } from "country-flags-svg";

function LSLanguageModalCont(props) {
  var svgStyle = {maxHeight: "10px",
                  marginBottom: "2px"};
  var modalContent = <React.Fragment>
    <span className="link" onClick={props.selectLanguageEn}><img alt="GB flag" style={svgStyle} src={findFlagUrlByIso2Code("GB")} /> GB</span><br />
    <span className="link" onClick={props.selectLanguageDe}><img alt="DE flag" style={svgStyle} src={findFlagUrlByIso2Code("DE")} /> DE</span><br />
    <span className="link" onClick={props.selectLanguageFr}><img alt="FR flag" style={svgStyle} src={findFlagUrlByIso2Code("FR")} /> FR</span><br />
    <span className="link" onClick={props.selectLanguageIt}><img alt="IT flag" style={svgStyle} src={findFlagUrlByIso2Code("IT")} /> IT</span><br />
    <span className="link" onClick={props.selectLanguageEs}><img alt="ES flag" style={svgStyle} src={findFlagUrlByIso2Code("ES")} /> ES</span><br />
    <span className="link" onClick={props.selectLanguageJa}><img alt="JA flag" style={svgStyle} src={findFlagUrlByIso2Code("JP")} /> JA</span><br />
    <span className="link" onClick={props.selectLanguageNl}><img alt="NL flag" style={svgStyle} src={findFlagUrlByIso2Code("NL")} /> NL</span>
  </React.Fragment>
  return (
    props.displayLanguageModal
    ? <LSModal title="Language Selection" titleId="app.button.selectLanguage" content={modalContent} modalToggle={props.toggleLanguageModal}/>
    : null)
}

const mapStateToProps = function(state) {
  return {displayLanguageModal: state.modalReducer.displayLanguageModal}
}

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    toggleLanguageModal: () => dispatch(toggleLanguageModal()),
    selectLanguageEn: () => dispatch(selectLanguage("en")),
    selectLanguageDe: () => dispatch(selectLanguage("de")),
    selectLanguageFr: () => dispatch(selectLanguage("fr")),
    selectLanguageJa: () => dispatch(selectLanguage("ja")),
    selectLanguageIt: () => dispatch(selectLanguage("it")),
    selectLanguageEs: () => dispatch(selectLanguage("es")),
    selectLanguageNl: () => dispatch(selectLanguage("nl"))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LSLanguageModalCont);
