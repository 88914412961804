import React from 'react';
import getDeviceInfo from '../../util/deviceValidation';
import LSLoading from '../../util/loading';
import progressTimer from '../progressTimer';
import ErrorHandler from '../../util/errorHandler';

export default class LSStudyEmbeddedStep extends React.Component {
  constructor(props) {
    super(props);
    this.handleError = this.handleError.bind(this);
    this.androidRerender = this.androidRerender.bind(this);

    this.iOSFrameRef = React.createRef();
    this.androidFrameRef = React.createRef();
  }

  handleError(userId, success) {
    if (!success) {
      let e = new Error("Start Tracking Embbeded Step Failed")
      ErrorHandler(e)
    }
  }

  iOSResponsiveCalc(parentNode) {
    let calcWidth = window.getComputedStyle(parentNode).width;
    let calcHeight = window.getComputedStyle(parentNode).height;
    calcWidth = parseInt(calcWidth.slice(0, -2));
    calcHeight = parseInt(calcHeight.slice(0, -2));
    calcWidth = calcWidth + 20;
    calcHeight = calcHeight + 20;
    calcWidth = calcWidth + "px";
    calcHeight = calcHeight + "px";
    return {width: calcWidth, height: calcHeight}
  }

  androidRerender(event) {
    let componentRef = this;
    if (!getDeviceInfo().os.family.includes("iOS")) {
      if (event.data.type === 'LRSP_TRACKER_LOADED') {
        componentRef.androidFrameRef.current.style.display = "none";
        setTimeout(() => { componentRef.androidFrameRef.current.style.display = "inline" }, 100);
      }
    }
  }

  componentDidMount() {
    let componentRef = this;
    var currentStep = this.props.currentStepOrderId;
    var currentStepId = this.props.stepData[currentStep].id;
    var currentStepOrderId = this.props.stepData[currentStep].order;
    if (getDeviceInfo().os.family.includes("iOS")) {
      let calcStyle = this.iOSResponsiveCalc(this.iOSFrameRef.current.parentNode);
      this.iOSFrameRef.current.style.width = calcStyle.width;
      this.iOSFrameRef.current.style.height = calcStyle.height;
    }
    window.addEventListener("message", this.androidRerender)
    progressTimer(this.props.progressAvailableTrue, this.props.stepData, currentStep);
    componentRef.props.et.startTracking(componentRef.props.studyReducer.studyId, componentRef.props.studyReducer.participant, [], componentRef.handleError, currentStepId, currentStepOrderId, componentRef.props.studyReducer.showGaze, true);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.androidRerender);
  }

  render() {
    var currentStep = this.props.currentStepOrderId;
    if (getDeviceInfo().os.family.includes("iOS")) {
      return (
        <React.Fragment>
          <iframe ref={this.iOSFrameRef} className="lumenIframeStandard" src={this.props.stepData[currentStep].url} allow="camera;autoplay;"></iframe>
          <LSLoading />
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <iframe ref={this.androidFrameRef} className="lumenIframeStandard" src={this.props.stepData[currentStep].url} allow="camera *;autoplay *;"></iframe>
          <LSLoading />
        </React.Fragment>
      );
    }
  }
}
