import React from 'react';
import ReactDOM from 'react-dom';
import './rollbar.js'
import './polyfill.js'
import './index.css';
import {Provider} from "react-redux";
import {persistor, store} from "./store/index";
import {PersistGate} from 'redux-persist/lib/integration/react';
import LSAppCont from './app/appCont.js';
// DEMO ALT
import { onBeforeLift } from 'components/util/preloadController';
import LSLoading from './components/util/loading';
//import * as serviceWorker from './serviceWorker';

if (process.env.REACT_APP_LUMEN_ENV_VALUE !== 'release') {
  window.store = store;
} else {
  // TODO: needs better security
  window.LSSTORE = (pw) => {
    if (btoa(pw) === "bHNfc3RvcmU=") {
      return store
    } else {
      return null
    }
  }
}

/**
 * Highest level of the app. It is here where it is rendered to the DOM.
 *
 */
ReactDOM.render(
<Provider store={store}>
  <PersistGate loading={<LSLoading />} persistor={persistor} onBeforeLift={onBeforeLift}>
    <LSAppCont/>
  </PersistGate>
</Provider>, document.getElementById('root'));

// Register the service worker
/*
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('service-worker.js').then(function(registration) {
    console.log('ServiceWorker registration successful with scope: ', registration.scope);
  }).catch(function(err) {
    console.log('ServiceWorker registration failed: ', err);
  });
}
*/
