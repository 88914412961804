const initialState = {
  displayHelpModal: false,
  helpTagActive: true
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'TOGGLE_HELP_MODAL':
      return {
        ...state,
        displayHelpModal: !state.displayHelpModal
      }
    case 'CHANGE_HELP_TAG':
      var newState = Object.assign({}, state);
      newState.helpTagActive = action.payload;
      return newState;
    default:
      return state
  }
};
