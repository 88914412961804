import React from 'react';
import { connect } from 'react-redux';
import LSProgressBar from '../progressBar';
import { progressBarFinished } from '../../../reducers/actions';

function LSProgressBarCont(props) {
  return (
    <LSProgressBar
      progressBarTiming={props.progressBarTiming}
      progressBarFinished={props.progressBarFinished}
      />
  )
}

const mapStateToProps = function(state) {
  return {
    progressBarTiming: state.baseReducer.progressBarTiming
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    progressBarFinished: () => dispatch(progressBarFinished())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LSProgressBar);
