import React from 'react';
// DEMO ALT
import StudyNextController from 'components/studyController/studyNextController';
import ErrorHandler from '../../util/errorHandler';

export default class LSStudyExternalStep extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    var componentRef = this;
    var currentStep = this.props.currentStepOrderId;
    var currentStepId = this.props.stepData[currentStep].id;
    let cb = (userId, success, sessionId) => {
      if (!success) {
        let e = new Error("External Step Redirect Failure")
        ErrorHandler(e)
      } else {
        StudyNextController.completeStep(() => {window.location.href = this.props.stepData[currentStep].url})
      }
    }
    this.props.et.inContextRedirect(componentRef.props.studyReducer.studyId, componentRef.props.studyReducer.participantId, [], currentStep, cb, componentRef.props.studyReducer.cellId, this.props.stepData[currentStep].id, componentRef.props.studyReducer.showGaze);
  }

  render() {
    return (
      null
    );
  }
}
