import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {FormattedMessage} from 'react-intl';
import { progressAvailableFalse } from '../../reducers/actions';
import {persistor} from "../../store/index";
import PanelistActionController from '../../components/util/panelistActionController';
import ROLLBAR from '../../rollbar.js'

/**
 * Completion template.
 */
export default class CompletionContent extends React.Component {
  constructor(props) {
    super(props);

    this.validationElementRef = React.createRef();

    this.redirectHandler = this.redirectHandler.bind(this);
    this.completionCB = this.completionCB.bind(this);
    this.cleanupCB = this.cleanupCB.bind(this);
  }

  redirectHandler() {
    persistor.purge()
    var modSuccessRedirectUrl = this.props.successRedirectUrl;
    window.location.href = modSuccessRedirectUrl.replace(this.props.redirectUrlMemberInsertParameter, this.props.participant);
  }

  completionCB() {
    console.log("complete")
  }

  cleanupCB(userId, success) {
    this.props.studyReducer.et.cleanup(this.props.studyReducer.studyId, this.props.studyReducer.participant, this.completionCB);
  }

  componentDidMount() {
    this.props.store.dispatch(progressAvailableFalse());

    this.props.studyReducer.et.completeSession(this.props.studyReducer.studyId, this.props.studyReducer.participant, this.cleanupCB, this.validationElementRef.current, 120000);
  }

  // TODO: Better variable completion text
  render() {
    return (<Container>
      <Row>
        <Col xs={12}>
          <div className="ls-main">
            <h1>Completion</h1>
              {this.props.studyId == 282 ? <p>Thank you! You have now completed this part of the study.</p> : <FormattedMessage id="app.completion.congratulations" defaultMessage="Congratulations! You have completed the study." description="Completion message"/>}<br></br>
              {!!this.props.successRedirectUrl ? <FormattedMessage id="app.generic.redirect" defaultMessage="You will be redirected in 3 seconds." description="Redirect message"/> : null}
          </div>
        </Col>
      </Row>
      <div id="validationElement" ref={this.validationElementRef}></div>
    </Container>);
  }
}
