const STATES = ["prestart", "index", "demographics", "instructions", "setup", "study", "completion", "error", "invalid", "debug", "preview"]

const initialState = {
  // base
  basestate: "prestart",
  progressAvailable: false,
  errorMessage: "Generic Error.",
  errorMessageId: "app.error.generic",
  isLoading: false,
  loadedAt: new Date(),
  deviceInfo: null,
  submissionInProgress: false,
  progressBarTiming: 0
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'PROGRESS_STATE':
      if (state.basestate === "completion" || state.basestate === "error") {
        console.log("CANNOT PROGRESS ANY FURTHER!");
        return state
      } else {
        let currentStateIndex = STATES.indexOf(state.basestate)
        return {
          ...state,
          basestate: STATES[currentStateIndex + 1]
        }
      }
    case 'REGRESS_STATE':
      if (state.basestate === "index") {
        console.log("CANNOT REGRESS ANY FURTHER!");
        return state
      } else {
        let currentStateIndex = STATES.indexOf(state.basestate)
        return {
          ...state,
          basestate: STATES[currentStateIndex - 1]
        }
      }
    case 'CHANGE_STATE_TO':
      if (STATES.indexOf(action.payload) === -1) {
        console.log("STATE " + action.payload + " DOES NOT EXISTS!");
        return state
      } else {
        return {
          ...state,
          basestate: action.payload
        }
      }
    case 'ERROR_STATE':
      if (!!action.payload) {
        return {
          ...state,
          errorMessage: action.payload.errorMessage,
          errorMessageId: action.payload.errorMessageId,
          basestate: "error"
        }
      } else {
        return {
          ...state,
          errorMessage: "Oops! Something went wrong.",
          errorMessageId: "app.error.generic",
          basestate: "error"
        }
      }
    case 'INVALIDATE_USER':
      return {
        ...state,
        basestate: "invalid"
      }
    case 'PROGRESS_AVAILABLE_TRUE':
      return {
        ...state,
        progressAvailable: true
      }
    case 'PROGRESS_AVAILABLE_FALSE':
      return {
        ...state,
        progressAvailable: false
      }
    case 'IS_LOADING_TRUE':
      return {
        ...state,
        progressAvailable: true
      }
    case 'IS_LOADING_FALSE':
      return {
        ...state,
        progressAvailable: false
      }
    case 'DEBUG_STATE':
      return {
        ...state,
        basestate: "debug"
      }
    case 'STORE_DEVICE_INFO':
      return {
        ...state,
        deviceInfo: action.payload
      }
    case 'SUBMISSION_IN_PROGRESS_TRUE':
      return {
        ...state,
        submissionInProgress: true
      }
    case 'SUBMISSION_IN_PROGRESS_FALSE':
      return {
        ...state,
        submissionInProgress: false
      }
    case 'PROGRESS_BAR_FINISHED':
      return {
        ...state,
        progressBarTiming: 0
      }
    case 'PROGRESS_BAR_TIMING':
      return {
        ...state,
        progressBarTiming: action.payload
      }
    default:
      return state
  }
};
