import * as actions from "./action-types";

export function progressState() {
  return {
    type: actions.PROGRESS_STATE
  };
}

export function regressState() {
  return {
    type: actions.REGRESS_STATE
  };
}

export function changeStateTo(payload) {
  return {
    type: actions.CHANGE_STATE_TO,
    payload
  };
}

export function errorState(payload = null) {
  return {
    type: actions.ERROR_STATE,
    payload: payload
  }
}

export function invalidateUser() {
  return {
    type: actions.INVALIDATE_USER
  }
}

export function progressAvailableTrue() {
  return {
    type: actions.PROGRESS_AVAILABLE_TRUE
  };
}

export function progressAvailableFalse() {
  return {
    type: actions.PROGRESS_AVAILABLE_FALSE
  };
}

export function isLoadingTrue() {
  return {
    type: actions.IS_LOADING_TRUE
  };
}

export function isLoadingFalse() {
  return {
    type: actions.IS_LOADING_FALSE
  };
}

export function debugState() {
  return {
    type: actions.DEBUG_STATE
  }
}

export function storeDeviceInfo(payload) {
  return {
    type: actions.STORE_DEVICE_INFO,
    payload: payload
  }
}

export function submissionInProgressTrue() {
  return {
    type: actions.SUBMISSION_IN_PROGRESS_TRUE
  }
}

export function submissionInProgressFalse() {
  return {
    type: actions.SUBMISSION_IN_PROGRESS_FALSE
  }
}

export function progressBarFinished() {
  return {
    type: actions.PROGRESS_BAR_FINISHED
  }
}

export function progressBarTiming(payload) {
  return {
    type: actions.PROGRESS_BAR_TIMING,
    payload: payload
  }
}
