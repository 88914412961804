import ROLLBAR from '../../rollbar.js';
import getDeviceInfo from './deviceValidation';
import ErrorHandler from './errorHandler';
import { store } from "../../store/index";
import { startResponseUpdate, assignResponseUpdate, sessionIdUpdate, updateInternalDemographicsQuestions } from '../lumenController/actions';
import { progressState, progressAvailableTrue, progressAvailableFalse, changeStateTo } from '../../reducers/actions';

function assignSessionHandler(props) {
  return new Promise(function(resolve, reject) {
    const urlParams = new URLSearchParams(window.location.search);
    const DEVICE_INFO = getDeviceInfo();
    let currentState = store.getState().studyReducer;
    fetch(process.env.REACT_APP_API_URL + "/participation/studies/" + currentState.studyId + "/assign/", {
        method: 'POST',
        body: JSON.stringify({
          access_code: currentState.accessCode,
          participant: currentState.participant,
          panel: currentState.panelCompanyCode,
          test: currentState.test,
          requested_cell_id: currentState.cellId,
          gender: currentState.demographicsSurveyResponsesInternal['gender'] || urlParams.get('g'),
          age: currentState.demographicsSurveyResponsesInternal['age'] || urlParams.get('a'),
          social_grade: currentState.demographicsSurveyResponsesInternal['social_grade'] || urlParams.get('s'),
          family: currentState.demographicsSurveyResponsesInternal['family'] || urlParams.get('f'),
          earnings: currentState.demographicsSurveyResponsesInternal['earnings'] || urlParams.get('hi'),
          ethnicity: currentState.demographicsSurveyResponsesInternal['ethnicity'] || urlParams.get('et'),
          extra1: currentState.demographicsSurveyResponsesInternal['e1'] || urlParams.get('e1'),
          extra2: currentState.demographicsSurveyResponsesInternal['e2'] || urlParams.get('e2'),
          extra3: currentState.demographicsSurveyResponsesInternal['e3'] || urlParams.get('e3'),
          device: DEVICE_INFO.device,
          os: DEVICE_INFO.osString.toLowerCase(),
          os_version: DEVICE_INFO.os.version,
          browser: DEVICE_INFO.name.toLowerCase(),
          browser_version: DEVICE_INFO.version,
          connection_speed: DEVICE_INFO.connectionType,
          connection_speed_absolute: (typeof DEVICE_INFO.connectionSpeed == "object" ? DEVICE_INFO.connectionSpeed.speedKbps : DEVICE_INFO.connectionSpeed),
          is_mobile: DEVICE_INFO.isMobile,
          is_touch_capable: DEVICE_INFO.isMobile,
          device_model: DEVICE_INFO.device,
          browser_language: window.navigator.language,
        })
      }).then(function(response) {
      if (response.ok) {
        return response.json();
      } else {
        let e = new Error(response.statusText);
        e.name = response.status || "";
        throw e
      }
    }).then(function(response) {
      if (!response.success) {
        let e = new Error(response.error);
        e.name = response.error_code || "";
        throw e
      } else {
        var assignPayload = {
          cellStatus: response.cell_status,
          cellId: response.cell_id,
          participantDetails: response.participant,
          participantStatus: response.cell_id,
          participantId: response.participant_id,
          stepData: response.step_data,
          fullScreenRequired: response.full_screen_required,
          hasExternalDomain: response.has_external_domain
        }
        props.assignResponseUpdate(assignPayload);
      }
    }).then(function() {
      return fetch(process.env.REACT_APP_TRACKING_URL + "/session", {
        method: 'POST',
        body: JSON.stringify({
          panelist: store.getState().studyReducer.participant
        })
      })
    }).then(function(response) {
      if (response.ok) {
        return response.json();
      } else {
        let e = new Error(response.statusText);
        e.name = response.status || "";
        throw e
      }
    }).then(function(response) {
      var sessionPayload = {
        sessionId: response.id
      }
      props.sessionIdUpdate(sessionPayload);
      resolve();
    }).catch(function(e) {
      reject(e)
    })
  })
}

function startHandler(props) {
  return new Promise(function(resolve, reject) {
    const DEVICE_INFO = getDeviceInfo();
    let currentState = store.getState().studyReducer;
    fetch(process.env.REACT_APP_API_URL + "/participation/studies/" + currentState.studyId + "/start/", {
      method: 'POST',
      body: JSON.stringify({
        access_code: currentState.accessCode,
        participant: currentState.participant,
        panel: currentState.panel,
        test: currentState.test,
        device: DEVICE_INFO.device,
        os: DEVICE_INFO.osString.toLowerCase(),
        os_version: DEVICE_INFO.os.version,
        browser: DEVICE_INFO.name.toLowerCase(),
        browser_version: DEVICE_INFO.version,
        connection_speed: DEVICE_INFO.connectionType,
        connection_speed_absolute: (typeof DEVICE_INFO.connectionSpeed == "object" ? DEVICE_INFO.connectionSpeed.speedKbps : DEVICE_INFO.connectionSpeed),
        is_mobile: DEVICE_INFO.isMobile,
        is_touch_capable: DEVICE_INFO.isMobile,
        device_model: DEVICE_INFO.device,
      })
    }).then(function(response) {
      if (response.ok) {
        return response.json();
      } else {
        let e = new Error(response.statusText);
        e.name = response.status || "";
        throw e
      }
    }).then(function(response) {
      if (!response.success) {
        let e = new Error(response.error);
        e.name = response.error_code || "";
        throw e
      } else {
        var startPayload = {
          brokerDetails: response.broker_details,
          cleanedParticipantReference: response.cleaned_participant_reference,
          demographicSurveyUrl: response.redirect_url,
          demographicSurveyUrlMemberInsertParameter: response.redirect_url_member_insert_parameter,
          orientation: response.orientation,
          requireChromeExtension: response.require_chrome_extension
        }
        props.startResponseUpdate(startPayload);
        if (typeof response.redirect_url !== 'undefined' || typeof response.study_questions !== 'undefined') {
          if (typeof response.study_questions !== 'undefined') {
            store.dispatch(updateInternalDemographicsQuestions(response.study_questions))
            store.dispatch(progressState());
            reject("BreakChainError");
          } else {
            var modDemographicSurveyUrl = response.redirect_url;
            window.location.href = modDemographicSurveyUrl.replace(response.redirect_url_member_insert_parameter, store.getState().studyReducer.participant);
            reject("BreakChainError");
          }
        } else {
          resolve();
        }
      }
    }).catch(function(e) {
      console.log(e);
      reject(e)
    })
  })
}

function startAssignHandler(props) {
  startHandler(props).then(function() {
    return assignSessionHandler(props)
  }).then(function(response) {
    if (props.studyContext.eyetrackingEnabled) {
      props.changeStateToInstructions();
    } else {
      props.changeStateToSetup();
    }

  }).catch(function(error) {
    // TODO: verbose error
    if (error !== "BreakChainError") {
      ErrorHandler(error)
    }
  })
}

function getStartAssignPayload() {
  return {
    basestate: store.getState().baseReducer.basestate,
    progressAvailable: store.getState().baseReducer.progressAvailable,
    studyContext: store.getState().studyReducer,
    progressState: () => store.dispatch(progressState()),
    progressAvailableTrue: () => store.dispatch(progressAvailableTrue()),
    progressAvailableFalse: () => store.dispatch(progressAvailableFalse()),
    startResponseUpdate: (payload) => store.dispatch(startResponseUpdate(payload)),
    assignResponseUpdate: (payload) => store.dispatch(assignResponseUpdate(payload)),
    sessionIdUpdate: (payload) => store.dispatch(sessionIdUpdate(payload)),
    changeStateToInstructions: () => store.dispatch(changeStateTo("instructions")),
    changeStateToSetup: () => store.dispatch(changeStateTo("setup")),
  }
}

export {startAssignHandler, assignSessionHandler, getStartAssignPayload}
