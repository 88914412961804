import React from 'react';
// DEMO ALT
import StudyNextController from 'components/studyController/studyNextController';

function detectQueryString(currentUrl) {
    // regex pattern for detecting ? character
    var pattern = new RegExp(/\?+/g);

    return pattern.test(currentUrl);
}

export default class LSStudyRedirectStep extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    var componentRef = this;
    var currentStep = this.props.currentStepOrderId;
    var currentStepId = this.props.stepData[currentStep].id;
    var redirectUrlQsBool = detectQueryString(this.props.stepData[currentStep].url);
    var redirectUrl = this.props.stepData[currentStep].url + (redirectUrlQsBool ? "&session_id=" : "?session_id=") + this.props.studyReducer.sessionId + (process.env.REACT_APP_LUMEN_ENV_VALUE === "release" ? "" : "&env=dev")
    let cb = (userId, success, sessionId) => {
      if (!success) {
        componentRef.props.errorState();
      } else {
        StudyNextController.completeStep(() => {window.location.href = redirectUrl})
      }
    }
    //this.props.et.inContextRedirect(componentRef.props.studyReducer.studyId, componentRef.props.studyReducer.participantId, [], currentStep, cb, componentRef.props.studyReducer.cellId, this.props.stepData[currentStep].id, componentRef.props.studyReducer.showGaze);
    window.location.href = this.props.stepData[currentStep].url
  }

  render() {
    return (
      null
    );
  }
}
