import { store } from "../../store/index";
import ROLLBAR from '../../rollbar.js';

function errorConstructor(error) {
  switch(error.name) {
    case "StartPanel-StudyNotLive":
      return {errorMessage: "Thanks you for attempting to take part in this study, however the study has now finished.", errorMessageId: "app.error.studynotlive"}
      break;
    case "StartStudy-StudyQuotaFull":
    case "StudyAssign-StudyQuotaFull":
      return {errorMessage: "The Study quota is full.", errorMessageId: "app.error.studyquotafull"}
      break;
    case "StartStudy-NoActiveCell":
    case "StudyAssign-NoActiveCell":
      return {errorMessage: "No active Cell has been found for this Study. Pre-Assign Check.", errorMessageId: "app.error.noactivecell"}
      break;
    case "StartStudy-NoBroker":
      return {errorMessage: "Broker not assigned to the Study.", errorMessageId: "app.error.nobroker"}
      break;
    case "StartStudy-InvalidDomainSetup":
      return {errorMessage: "Invalid Domain included in setup.", errorMessageId: "app.error.invaliddomainsetup"}
      break;
    case "StartStudy-OSNotAllowed":
      return {errorMessage: "Your OS not allowed, please check which device you can use for this survey and try again.", errorMessageId: "app.error.osnotallowed"}
      break;
    case "StartStudy-BrowserNotAllowed":
      return {errorMessage: "Browser not allowed.", errorMessageId: "app.error.browsernotallowed"}
      break;
    case "StartStudy-OSBrowserNotAllowed":
      return {errorMessage: "OS / Browser not allowed.", errorMessageId: "app.error.osbrowsernotallowed"}
      break;
    case "StartStudy-LowConnectionSpeed":
      return {errorMessage: "Connection speed determined to be too low.", errorMessageId: "app.error.lowconnectionspeed"}
      break;
    case "StartStudy-ConnectionSpeedNotAllowed":
      return {errorMessage: "Connection speed determined to be too low.", errorMessageId: "app.error.connectionspeednotallowed"}
      break;
    case "CompletePositionCalibration-Failure":
      return {errorMessage: "General device performance too low this study.", errorMessageId: "app.error.positioncalibrationfailure"}
      break;
    case "StartStudy-NoOSBrowserFilterSet":
      return {errorMessage: "It appears the study you are trying to view hasn't had its browser / os filter set!", errorMessageId: "app.error.noosbrowserfilterset"}
      break;
    case "StudyAssign-RepeatParticipant":
      return {errorMessage: "You have already been assigned to this Study. We do not allow repeats", errorMessageId: "app.error.repeatparticipant"}
      break;
    case "StudyAssign-CellDemographicQuotaFull-NoActiveCell":
      return {errorMessage: "Thank you for your interest. This study is now closed to your demographic.", errorMessageId: "app.error.demoquotafullnoactivecell"}
      break;
    case "StudyAssign-CellQuotaFull-NoActiveCell":
      return {errorMessage: "No active Cell has been found for this Study. Cell Quotas are full.", errorMessageId: "app.error.cellquotafullnoactivecell"}
      break;
    case "StudyAssign-InvalidEyeTrackingDomainSetup":
      return {errorMessage: "Invalid Domain included in setup.", errorMessageId: "app.error.invaliddomainsetup"}
      break;
    case "StudyAssign-RepeatedStepSetup":
      return {errorMessage: "Invalid Step included in setup.", errorMessageId: "app.error.repeatedstepsetup"}
      break;
    case "StudySetup-InitialiseFail":
      ROLLBAR.error(error);
      return {errorMessage: "Unable to initialise!", errorMessageId: "app.error.initialise"}
      break;
    case "StudySetup-FindCameraFail":
      ROLLBAR.info(error);
      return {errorMessage: "Unable to find camera!", errorMessageId: "app.error.findCamera"}
      break;
    case "StudySetup-HeadPositionFail":
      ROLLBAR.error(error);
      return {errorMessage: "Unable to determine your head position.", errorMessageId: "app.error.headPosition"}
      break;
    case "StudySetup-CalibrateFail":
      ROLLBAR.error(error);
      return {errorMessage: "Unable to calibrate.", errorMessageId: "app.error.calibrate"}
      break;
    case "Study-ValidateFail":
      ROLLBAR.info("Validation Failure");
      return {errorMessage: "Oops! Something went wrong.", errorMessageId: "app.error.generic"};
      break;
    default:
      error.message = error.message.replace(/u'(?=[^:]+')/g, "'").replace(/[\[\]']+/g,'');
      ROLLBAR.error(error);
      return {errorMessage: "Oops! Something went wrong.", errorMessageId: "app.error.generic"}
  }
}

export default function ErrorHandler(error = null) {
  let errorState = (payload = null) => {
    store.dispatch({type: "ERROR_STATE", payload: payload})
  }
  error !== null ? errorState(errorConstructor(error)) : errorState()
}
