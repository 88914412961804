import React from 'react';
import {connect} from 'react-redux';
import LSStudyStep from '../studyStep'
import { progressState, progressAvailableTrue, progressAvailableFalse, errorState } from '../../../../reducers/actions';

function LSStudyStepCont(props) {
  return (
    <LSStudyStep
      validationElementRef={props.validationElementRef}
      errorMessage={props.errorMessage}
      errorMessageId={props.errorMessageId}
      progressState={props.progressState}
      progressAvailableTrue={props.progressAvailableTrue}
      progressAvailableFalse={props.progressAvailableFalse}
      currentStepId={props.currentStepId}
      currentStepOrderId={props.currentStepOrderId}
      stepData={props.stepData}
      et={props.et}
      errorState={props.errorState}
      studyReducer={props.studyReducer}
      selectedLanguage={props.selectedLanguage}
      basestate={props.basestate} />
  )
}

const mapStateToProps = function(state) {
  return {
    basestate: state.baseReducer.basestate,
    errorMessage: state.baseReducer.errorMessage,
    errorMessageId: state.baseReducer.errorMessageId,
    currentStepId: state.studyReducer.currentStepId,
    currentStepOrderId: state.studyReducer.currentStepOrderId,
    stepData: state.studyReducer.stepData,
    et: state.studyReducer.et,
    studyReducer: state.studyReducer,
    selectedLanguage: state.modalReducer.selectedLanguage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    progressState: () => dispatch(progressState()),
    progressAvailableTrue: () => dispatch(progressAvailableTrue()),
    progressAvailableFalse: () => dispatch(progressAvailableFalse()),
    errorState: () => dispatch(errorState())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LSStudyStepCont);
