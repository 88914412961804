import React from 'react';
// DEMO ALT
import StudyNextController from 'components/studyController/studyNextController';
import getDeviceInfo from '../../util/deviceValidation';
import LSIntlFormat from '../../util/intlFormat';
import ErrorHandler from '../../util/errorHandler';
import {handleFullscreenOpen, handleFullscreenClose} from '../../util/fullscreenController';

export default class LSStudyRecalibrateStep extends React.Component {
  constructor(props) {
    super(props);

    this.headPositionFinish = this.headPositionFinish.bind(this);
    this.recalibrationCB = this.recalibrationCB.bind(this);
  }

  recalibrationCB(userId, success) {
    handleFullscreenClose(this.props.studyReducer.fullScreenRequired);
    if (!success) {
      let e = new Error("Recalibration Failure");
      ErrorHandler(e);
    } else {
      StudyNextController.callNext();
    }
  }

  headPositionFinish(userId, success) {
    if (!success) {
      let e = new Error("Recalibration Failure");
      ErrorHandler(e);
    } else {

      handleFullscreenOpen(this.props.studyReducer.fullScreenRequired);

      let instructionObj = {
        top: LSIntlFormat.IntlMessageFormat("app.setup.calibrationProp.watch", "Watch this dot").toUpperCase(),
        bottom: getDeviceInfo().isMobile ? LSIntlFormat.IntlMessageFormat("app.setup.calibrationProp.tap", "Tap anywhere to continue").toUpperCase()
                           : LSIntlFormat.IntlMessageFormat("app.setup.calibrationProp.click", "Click anywhere to continue").toUpperCase()
      }
      // Used for setting custom calibration point locations
      let calibPointsDesktop = null;
      let calibPointsMobile = null;
      if (this.props.studyReducer.cellId === 1374 || this.props.studyReducer.cellId === 1372 || this.props.studyReducer.cellId === 1304) {
        calibPointsMobile = [[50,50],[5,5],[5,95],[50,95],[70,95],[95,95],[95,5],[70,5],[50,5],[50,50]]
      }
      this.props.studyReducer.et.recalibrate(this.props.studyReducer.studyId, this.props.studyReducer.participant, this.props.calibrationElementRef.current, this.recalibrationCB, instructionObj.top, instructionObj.bottom, 120000, calibPointsDesktop, calibPointsMobile);
    }
  }

  componentDidMount() {
    var timeoutCounter = 0;
    var timeout = 1500;
    var tickrate = 30;

    let instructionObj = {
      bottom: getDeviceInfo().isMobile ? LSIntlFormat.IntlMessageFormat("app.setup.calibrationProp.tap", "Tap anywhere to continue").toUpperCase()
                         : LSIntlFormat.IntlMessageFormat("app.setup.calibrationProp.click", "Click anywhere to continue").toUpperCase()
    }

    const checkIfReady = () => {
      if (typeof this.props.headPositionElementRef.current !== 'undefined' && typeof this.props.calibrationElementRef.current !== 'undefined') {
        this.props.studyReducer.et.isHeadPositionReady(this.props.studyReducer.studyId,this.props.studyReducer.participant, this.props.headPositionElementRef.current, this.headPositionFinish, instructionObj.bottom, 30000)
      } else if (timeoutCounter > (timeout / tickrate)) {
        let e = new Error("Validation Element Ref Failed to Appear");
        ErrorHandler(e);
      } else {
        timeoutCounter++
        setTimeout(checkIfReady, tickrate);
      }
    }
    setTimeout(checkIfReady, tickrate);
  }

  render() {
    return (
      null
    );
  }
}
