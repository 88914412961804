import { IntlProvider } from 'react-intl';
import {addLocaleData} from "react-intl";
import locale_en from 'react-intl/locale-data/en';
import locale_de from 'react-intl/locale-data/de';
import locale_fr from 'react-intl/locale-data/fr';
import locale_ja from 'react-intl/locale-data/ja';
import locale_it from 'react-intl/locale-data/it';
import locale_es from 'react-intl/locale-data/es';
import locale_nl from 'react-intl/locale-data/nl';
import messages_en from "../../translations/en.json";
import messages_de from "../../translations/de.json";
import messages_fr from "../../translations/fr.json";
import messages_ja from "../../translations/ja.json";
import messages_it from "../../translations/it.json";
import messages_es from "../../translations/es.json";
import messages_nl from "../../translations/nl.json";
import { store } from "../../store/index";

addLocaleData([
  ...locale_en,
  ...locale_de,
  ...locale_fr,
  ...locale_ja,
  ...locale_it,
  ...locale_es,
  ...locale_nl,
]);

const messages = {
  'en': messages_en,
  'de': messages_de,
  'fr': messages_fr,
  'ja': messages_ja,
  'it': messages_it,
  'es': messages_es,
  'nl': messages_nl
};

export default class LSIntelFormat {
  static IntlMessageFormat(id, defaultMessage) {
    let { intl } = new IntlProvider({ locale: store.getState().modalReducer.selectedLanguage, messages: messages[store.getState().modalReducer.selectedLanguage] }, {}).getChildContext();
    return intl.formatMessage({id: id, defaultMessage: defaultMessage});
  }
}
