import { store } from "../../../store/index";
import { progressState, progressAvailableFalse, errorState } from '../../../reducers/actions';
import { incrementStudyStep } from '../../../components/lumenController/actions';
import ROLLBAR from '../../../rollbar.js'

export default class StudyNextController {
  static callNext() {

    const nextCB = (userId, success) => {
      if (!success) {
        store.dispatch(errorState());
      } else {
        var currentStep = store.getState().studyReducer.currentStepOrderId;
        var stepData = store.getState().studyReducer.stepData;
        if (currentStep === stepData.length -1) {
          store.dispatch(progressState());
        } else {
          store.dispatch(incrementStudyStep());
        }
      }
    }
    store.dispatch(progressAvailableFalse());
    if (store.getState().studyReducer.et.is_tracking) {
      store.getState().studyReducer.et.stopTracking(store.getState().studyReducer.studyId, store.getState().studyReducer.participant, nextCB );
    } else {
      nextCB(store.getState().studyReducer.participantId, true)
    }
  }

  static completeStep(callback) {
    callback();
  }
}
