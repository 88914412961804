import React from 'react';
//import LSHelpModalCont from './containers/helpModal'
import LSFaqModalCont from '../modal/containers/faqModal'
import {FormattedMessage} from 'react-intl';

/**
 * Help tag componenet.
 * To be upgraded with a form.
 */
export class LSHelpTag extends React.Component {
  constructor(props) {
    super(props);
    this.mouseOut = this.mouseOut.bind(this);
    this.mouseOver = this.mouseOver.bind(this);
  }

  componentDidMount() {
    let classVar = this;
    setTimeout(function() {
      classVar.props.disableHelpTag()
    }, 1500);
  }

  mouseOut() {
    this.props.disableHelpTag();
  }

  mouseOver() {
    this.props.enableHelpTag();
  }

  render() {
    return (<div className="help-cont desktopOnly">
      <div className="obscure" onMouseOut={() => this.mouseOut()} onMouseOver={() => this.mouseOver()}></div>
      <div className={this.props.helpTagActive
          ? "help-tag help-tag-active"
          : "help-tag"} onMouseOut={() => this.mouseOut()} onMouseOver={() => this.mouseOver()} onClick={() => this.props.toggleFaqModal()}>
        <FormattedMessage id="app.button.help" defaultMessage="Help" description="Help Button"/>
      </div>
      
    </div>);
  }
}

/*
  for when the help tag is used later
{
  this.props.displayHelpModal
    ? <LSFaqModalCont />
    : null
}

*/
