import React from 'react';
// DEMO ALT
import StudyNextController from 'components/studyController/studyNextController';
import { Player, ControlBar, PlayToggle, VolumeMenuButton } from 'video-react';
import ROLLBAR from '../../../rollbar.js';
import progressTimer from '../progressTimer';
import ErrorHandler from '../../util/errorHandler';

export default class LSZoomStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stimSrc: null,
      stimHtmlConstructorType: null,
      clickHandled: false
    }

    this.storedStepId = null;
    this.playerSubscribed = false;
    this.endHandled = false;
    this.zoomSrc = React.createRef();
    this.zoomFrame = React.createRef();
    this.zoomLens = React.createRef();

    this.htmlConstructor = this.htmlConstructor.bind(this);
    this.handleError = this.handleError.bind(this);
    this.internalRender = this.internalRender.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
    this.imageZoom = this.imageZoom.bind(this);
  }

  clickHandler() {
    if (!this.state.clickHandled) {
      this.setState((state) => {
        return {
          ...state,
          clickHandled: true
        }
      })
      StudyNextController.callNext();
    }
  }


  imageZoom() {
    // TODO: Refactor
    var img, lens, result, cx, cy;
    img = this.zoomSrc.current;
    result = this.zoomFrame.current;
    /*modify lens:*/
    lens = this.zoomLens.current;
    var lensRatio = 2;
    lens.style.width = (img.offsetWidth / lensRatio) + "px";
    lens.style.height = (img.offsetHeight / lensRatio) + "px";
    result.style.width = img.offsetWidth + "px";
    result.style.height = img.offsetHeight + "px";
    /*calculate the ratio between result DIV and lens:*/
    result.children[0].style.width = result.children[0].naturalWidth / (img.offsetWidth / lens.offsetWidth) + "px"
    result.children[0].style.height = result.children[0].naturalHeight / (img.offsetHeight / lens.offsetHeight) + "px"
    cx = (result.children[0].offsetWidth / lens.offsetWidth);
    cy = (result.children[0].offsetHeight / lens.offsetHeight);
    /*execute a function when someone moves the cursor over the image, or the lens:*/
    lens.addEventListener("mousemove", moveLens);
    img.addEventListener("mousemove", moveLens);
    /*and also for touch screens:*/
    lens.addEventListener("touchmove", moveLens);
    img.addEventListener("touchmove", moveLens);
    /* show result on mouseover */
    lens.addEventListener("mouseover", showResult);
    img.addEventListener("mouseover", showResult);
    /* hide result on mouseexit */
    lens.addEventListener("mouseleave", hideResult);
    img.addEventListener("mouseleave", hideResult);

    function moveLens(e) {
      if (!img.style.opacity) {
        showResult(e);
      }
      var pos, x, y;
      /*prevent any other actions that may occur when moving over the image:*/
      e.preventDefault();
      /*get the cursor's x and y positions:*/
      pos = getCursorPos(e);
      /*calculate the position of the lens:*/
      x = pos.x - (lens.offsetWidth / lensRatio);
      y = pos.y - (lens.offsetHeight / lensRatio);
      /*prevent the lens from being positioned outside the image:*/
      if (x > img.width - lens.offsetWidth) {x = img.width - lens.offsetWidth;}
      if (x < img.width / cx) {x = img.width / cx;}
      if (y > img.height - lens.offsetHeight) {y = img.height - lens.offsetHeight;}
      if (y < img.height / cy) {y = img.height / cy;}
      /*set the position of the lens:*/
      lens.style.left = x + "px";
      lens.style.top = y + "px";
      /*display what the lens "sees":*/
      // result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
      result.children[0].style.top = ((y * cy) - result.offsetHeight) * -1 + "px";
      result.children[0].style.left = ((x * cx) - result.offsetWidth) * -1 + "px";
    }
    function showResult(e) {
    	result.style.visibility = "visible";
      img.style.opacity = 0;
    }
    function hideResult(e) {
    	result.style.visibility = "hidden";
      img.style.opacity = 100;
    }
    function getCursorPos(e) {
      var a, x = 0, y = 0;
      e = e || window.event;
      /*get the x and y positions of the image:*/
      a = img.getBoundingClientRect();
      /*calculate the cursor's x and y coordinates, relative to the image:*/
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /*consider any page scrolling:*/
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return {x : x, y : y};
    }
  }

  htmlConstructor() {
    var currentStep = this.props.currentStepOrderId;
    switch (this.state.stimHtmlConstructorType) {
      case "img":
        return <>
                 <div ref={this.zoomLens} className="ls-imgZoomLens"></div>
                 <img id="zoomSrc" className="ls-zoomSrc" alt="Lumen Zoom Source" ref={this.zoomSrc} src={this.state.stimSrc}></img>
                 <div id="zoomFrame" ref={this.zoomFrame} className="ls-zoomResult">
                   <img id="lumenStim" className="ls-zoomResultImg" alt="Lumen stim" src={this.state.stimSrc + "?zoomResult=true"}></img>
                 </div>
               </>
      default:
        return null
    }
  }

  handleError(userId, success) {
    if (!success) {
      let e = new Error("Prod Picker Start Tracking Failure")
      ErrorHandler(e)
    }
  }

  internalRender() {
    var resultInternal = null;
    let componentRef = this;
    var currentStep = this.props.currentStepOrderId;
    var currentStepId = this.props.stepData[currentStep].id;
    var currentStepOrderId = this.props.stepData[currentStep].order;
    this.storedStepId = currentStep;
    fetch(this.props.stepData[currentStep].stim_server_url).then(function(response) {
      componentRef.resourceFetched = true;
      if (response.ok) {
        return response.json();
      } else {
        let e = new Error(response.statusText);
        e.name = response.status || "";
        throw e
      }
    }).then(function(response) {
      var parser = new DOMParser();
      var doc = parser.parseFromString(response.final_html, "text/html");
      resultInternal = doc.body.children[0].src;
      if (doc.body.children[0].tagName.toLowerCase() === "img") {
        componentRef.setState((state) => {
          return {
            ...state,
            stimSrc: resultInternal,
            stimHtmlConstructorType: "img"
          }
        })
      }
      setTimeout(componentRef.imageZoom, 1000);
      if (componentRef.props.stepData[currentStep].time_to_show_next_button_ms >= 0 && componentRef.props.stepData[currentStep].time_to_move_next_button_ms >= 0) {
        progressTimer(componentRef.props.progressAvailableTrue, componentRef.props.stepData, currentStep);
      } else if (doc.body.children[0].tagName.toLowerCase() === "img" && componentRef.props.stepData[currentStep].time_to_move_next_button_ms < 0) {
        progressTimer(componentRef.props.progressAvailableTrue, componentRef.props.stepData, currentStep, 10000);
      }
      componentRef.props.trackingStartTime(new Date());
      componentRef.props.et.startTracking(componentRef.props.studyReducer.studyId, componentRef.props.studyReducer.participant, ["zoomSrc"], componentRef.handleError, currentStepId, currentStepOrderId, componentRef.props.studyReducer.showGaze);
    }).catch(function(error) {
      ErrorHandler(error)
    })
  }

  componentDidMount() {
    this.internalRender();
  }

  handleEnded(state, prevState) {
    if ((state.currentTime == state.duration) && state.paused && this.endHandled === false) {
      this.endHandled = true;
      setTimeout(StudyNextController.callNext, 1000);
    }
  }

  componentDidUpdate() {
    if (this.props.currentStepOrderId !== this.storedStepId) {
      this.setState((state) => {
        return {
          stimSrc: null,
          stimHtmlConstructorType: null
        }
      })
      this.internalRender();
    }
    setTimeout(() => {
      if (typeof this.player !== 'undefined' && this.playerSubscribed !== true) {
        this.playerSubscribed = true;
        var currentStep = this.props.currentStepOrderId;
        if (this.props.stepData[currentStep].time_to_show_next_button_ms < 0 || this.props.stepData[currentStep].time_to_move_next_button_ms < 0) {
          this.player.subscribeToStateChange(this.handleEnded.bind(this));
        }
      }
    }, 1000)
  }

  render() {
    // TODO (Translation): Below h3 tags need to be translated into FR and DE
    return (
      <React.Fragment>
        {this.htmlConstructor()}
      </React.Fragment>
    );
  }
}
