import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import ROLLBAR from '../../../../rollbar.js';
import thumbsUp from '../../../../components/studyController/thumbsUp.png';
import progressTimer from '../../../../components/studyController/progressTimer';

export default class LSStudyInstructionStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      instructionText: null
    }

    this.storedStepId = null;

    this.internalRender = this.internalRender.bind(this);
  }

  internalRender() {
    let componentRef = this;
    var currentStep = this.props.currentStepOrderId;
    var currentStepId = this.props.stepData[currentStep].id;
    var resultInternal = null;
    this.storedStepId = currentStep;
    fetch(process.env.REACT_APP_API_URL_NO_VER + "/task_pages/page_json/" + this.props.stepData[currentStep].page_id + "?language=" + componentRef.props.selectedLanguage).then(function(response) {
      if (response.ok) {
        return response.json();
      } else {
        // TODO: handle error
        throw response.status + " " + response.statusText;
      }
    }).then(function(response) {
      resultInternal = !!response.content ? response.content : response.default_content;
      componentRef.setState((state) => {
        return {instructionText: resultInternal}
      })
    }).catch(function(error) {
      if (!!error.errorMessage) {
        ROLLBAR.error(error.errorMessage)
      } else if (typeof error === 'object') {
        ROLLBAR.error(error.message)
      } else {
        ROLLBAR.error(error)
      }
      componentRef.props.errorState();
    })
    progressTimer(this.props.progressAvailableTrue, this.props.stepData, currentStep, 1000);
  }

  componentDidMount() {
    this.internalRender();
  }

  componentDidUpdate() {
    if (this.props.currentStepOrderId !== this.storedStepId) {
      this.internalRender();
    }
  }

  render() {
    return (
      <Row className="instructionRow">
        <Col xs={12} sm={3} className="centerAlign"><img className="instructionImage" src={thumbsUp} /></Col>
        <Col xs={12} sm={9}><pre>{this.state.instructionText}</pre></Col>
      </Row>
    );
  }
}
