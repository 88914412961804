import Rollbar from "rollbar";
import { store } from "./store/index";

const ROLLBAR = new Rollbar({
      accessToken: "445598a05850426583db5e7c20fd7357",
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
          environment: process.env.REACT_APP_LUMEN_ENV_VALUE == "release" ? "production" : "development",
          currentState: null,
      }
  });

ROLLBAR.configure({onSendCallback: function(isUncaught, args, payload) {
    payload.currentState = store.getState();
}});

export default ROLLBAR;
