import React from 'react';
import { connect } from 'react-redux';
import LSHeader from '../header';

function LSHeaderCont(props) {
  return (
    <LSHeader
      progressBarTiming={props.progressBarTiming}
      />
  )
}

const mapStateToProps = function(state) {
  return {
    progressBarTiming: state.baseReducer.progressBarTiming
  }
}

export default connect(mapStateToProps)(LSHeaderCont);
