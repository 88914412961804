import React from 'react';
// DEMO ALT
import StudyNextController from 'components/studyController/studyNextController';
import getDeviceInfo from '../../util/deviceValidation';
import LSIntlFormat from '../../util/intlFormat';
import ErrorHandler from '../../util/errorHandler';

export default class LSStudyValidationStep extends React.Component {
  constructor(props) {
    super(props);

    this.validationCB = this.validationCB.bind(this);
  }

  validationCB(userId, success) {
    if (!success) {
      let e = new Error("Study-ValidateFail");
      ErrorHandler(e);
    } else {
      StudyNextController.callNext();
    }
  }

  componentDidMount() {
    let skipSetup = this.props.studyReducer.brokerDetails.url.includes("/mobile_mouse_broker/") || this.props.studyReducer.brokerDetails.url.includes("/mouse_broker/") || this.props.studyReducer.brokerDetails.url.includes("/no_eyetracking_broker/");
    if(skipSetup) {
      StudyNextController.callNext();
      return
    }
    var timeoutCounter = 0;
    var timeout = 1500;
    var tickrate = 30;
    var currentStep = this.props.currentStepOrderId;
    // var currentStepId = this.props.stepData[currentStep].id;
    var currentStepOrderId = this.props.stepData[currentStep].order;
    let instructionObj = {
      top: LSIntlFormat.IntlMessageFormat("app.study.validationProp.watch", "Watch this dot").toUpperCase(),
      restart: LSIntlFormat.IntlMessageFormat("app.study.validationProp.restart", "Please try again").toUpperCase(),
      bottom: getDeviceInfo().isMobile ? LSIntlFormat.IntlMessageFormat("app.study.validationProp.tap", "Tap anywhere to continue").toUpperCase()
                         : LSIntlFormat.IntlMessageFormat("app.study.validationProp.click", "Click anywhere to continue").toUpperCase()
    }
    const checkIfReady = () => {
      if (typeof this.props.validationElementRef.current != 'undefined') {
        // Used for setting custom validation point locations
        let validationLocations = null;
        // AB Test for study 353 and 355
        if (this.props.studyReducer.cellId === 1160 || this.props.studyReducer.cellId === 1168) {
          validationLocations = [[50, 50], [5,5], [5,95], [50,95], [95,95], [95,5], [50,5]];
          console.log(validationLocations)
        }
        this.props.et.validate(this.props.studyReducer.studyId, this.props.studyReducer.participantId, this.props.studyReducer.participant, this.props.validationElementRef.current, this.props.stepData[currentStep].id, currentStepOrderId, this.validationCB, instructionObj.top, instructionObj.bottom, instructionObj.restart, 120000, this.props.studyReducer.cellId, validationLocations, this.props.studyReducer.calibrationInfo);
      } else if (timeoutCounter > (timeout / tickrate)) {
        let e = new Error("Validation Element Ref Failed to Appear");
        ErrorHandler(e);
      } else {
        timeoutCounter++
        setTimeout(checkIfReady, tickrate);
      }
    }
    setTimeout(checkIfReady, tickrate);
  }

  render() {
    return (
      null
    );
  }
}
