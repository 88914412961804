import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';
import { progressAvailableFalse } from '../../reducers/actions';
import {persistor} from "../../store/index";
import PanelistActionController from '../../components/util/panelistActionController';
import ErrorHandler from '../../components/util/errorHandler';
import ROLLBAR from '../../rollbar.js'

/**
 * Error template.
 */
export default class InvalidContent extends React.Component {
  constructor(props) {
    super(props);

    this.redirectHandler = this.redirectHandler.bind(this);
  }

  redirectHandler() {
    persistor.purge()
    var modSuccessRedirectUrl = this.props.screenoutRedirectUrl;
    window.location.href = modSuccessRedirectUrl.replace(this.props.redirectUrlMemberInsertParameter, this.props.participant);
  }

  componentDidMount() {
    this.props.store.dispatch(progressAvailableFalse());
    PanelistActionController.panelistAction("LS:VisitInvalidPage");
    var componentRef = this;
    var failPayload;
    // TODO: optimise with spread operator
    if (!!!componentRef.props.participantId) {
      failPayload = {
        access_code: componentRef.props.accessCode,
        participant: componentRef.props.participant,
        reason: "Invalid"
      }
    } else {
      failPayload = {
        access_code: componentRef.props.accessCode,
        participant_id: componentRef.props.participantId,
        reason: "Invalid"
      }
    }

    fetch(process.env.REACT_APP_API_URL + "/participation/studies/" + this.props.studyId + "/fail/", {
      method: 'POST',
      body: JSON.stringify(failPayload)
    }).then(function(response) {
      if (response.ok) {
        return response.json();
      } else {
        let e = new Error(response.statusText);
        e.name = response.status || "";
        throw e
      }
    }).then(function(response) {
      if (!!componentRef.props.screenoutRedirectUrl) {
        setTimeout(componentRef.redirectHandler, 3000);
      }
    }).catch(function(error) {
      ROLLBAR.error(error)
      if (!!componentRef.props.screenoutRedirectUrl) {
        setTimeout(componentRef.redirectHandler, 3000);
      }
    })
  }

  render() {
    return (<Container>
      <Row>
        <Col xs={12}>
          <div className="ls-main">
            <h1>Invalid</h1>
            <p>
              <FormattedMessage id="app.invalid.generic" defaultMessage="Sorry, you are not eligible for this study." description="Invalid message"/><br></br>
              {!!this.props.screenoutRedirectUrl ? <FormattedMessage id="app.generic.redirect" defaultMessage="You will be redirected in 3 seconds." description="Redirect message"/> : null}
            </p>
          </div>
        </Col>
      </Row>
    </Container>);
  }
}
