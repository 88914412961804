import React from 'react';
import ReactDOM from 'react-dom';
import {FormattedMessage} from 'react-intl';

const root = document.getElementById('root');

/**
 * Primary modal fragment from which all modals are to be derived. Wrapped in a portal.
 */
class ModalFragment extends React.Component {
  render() {
    return (<div className="ls-overlay">
      <div className="ls-modal">
        <i onClick={() => this.props.modalToggle(this.props.modalState)} className="fas fa-times ls-modalclose"></i>
        <h2><FormattedMessage id={this.props.titleId} defaultMessage={this.props.title} description="Modal Title"/></h2>
        <div className="ls-modalContentWrap">
          {this.props.content}
        </div>
      </div>
      <div onClick={() => this.props.modalToggle(this.props.modalState)} className="ls-modalbg"></div>
    </div>);
  }
}

export default class LSModal extends React.Component {
  render() {
    return ReactDOM.createPortal(<ModalFragment title={this.props.title} titleId={this.props.titleId} content={this.props.content} modalToggle={this.props.modalToggle} modalState={this.props.modalState}/>, root);
  }
}
