import { store } from "../../store/index";
import { progressState, progressAvailableFalse, errorState, submissionInProgressTrue, submissionInProgressFalse } from '../../reducers/actions';
import { incrementStudyStep, showStimulusQuestion, hideStimulusQuestion } from '../lumenController/actions';
import PanelistActionController from '../util/panelistActionController';
import ErrorHandler from '../util/errorHandler';
import ROLLBAR from '../../rollbar.js'

export default class StudyNextController {
  static callNext() {

    const nextCB = (userId, success) => {
      if (!success) {
        store.dispatch(errorState());
      } else {
        var currentStep = store.getState().studyReducer.currentStepOrderId;
        var stepData = store.getState().studyReducer.stepData;
        var stepId = store.getState().studyReducer.stepData[currentStep].id;
        var accessCode = store.getState().studyReducer.accessCode;
        var participantId = store.getState().studyReducer.participantId;
        var studyId = store.getState().studyReducer.studyId;
        let isStimulusQuestion = store.getState().studyReducer.stepData[currentStep].type === "StimulusQuestionStep";

        if (currentStep === stepData.length -1) {
          store.dispatch(submissionInProgressFalse())
          store.dispatch(progressState())
        } else {
          var stepPayload = {
            access_code: accessCode,
            participant_id: participantId,
            step_id: stepId
          }
          fetch(process.env.REACT_APP_API_URL + "/participation/studies/" + studyId + "/complete-step/", {
            method: 'POST',
            body: JSON.stringify(stepPayload)
          }).then(function(response) {
            if (response.ok) {
              // TODO: Currently not working for all steps, sort this out!
              return response.json();
            } else {
              let e = new Error(response.statusText);
              e.name = response.status || "";
              throw e
            }
          }).then(function() {
            PanelistActionController.panelistAction("LS:CompleteStep" + stepId);
            store.dispatch(incrementStudyStep());
            store.dispatch(submissionInProgressFalse());
          }).catch(function(error) {
            ErrorHandler(error)
          })
        }
      }
    }

    let currentStep = store.getState().studyReducer.currentStepOrderId;
    let isStimulusQuestion = store.getState().studyReducer.stepData[currentStep].type === "StimulusQuestionStep";

    store.dispatch(progressAvailableFalse());
    let trackingStartTime = store.getState().studyReducer.trackingStartTime;
    let currentTime = new Date();
    if (trackingStartTime !== null && currentTime - trackingStartTime > 60000) {
      store.dispatch(submissionInProgressTrue())
    }
    if (store.getState().studyReducer.et.is_tracking) {
      store.getState().studyReducer.et.stopTracking(store.getState().studyReducer.studyId, store.getState().studyReducer.participant, isStimulusQuestion ? store.dispatch(showStimulusQuestion()) : nextCB );
    } else {
      if (isStimulusQuestion) {
        store.dispatch(showStimulusQuestion());
      } else {
        nextCB(store.getState().studyReducer.participantId, true);
      }
    }

  }

  static completeStep(callback) {
    var currentStep = store.getState().studyReducer.currentStepOrderId;
    var stepId = store.getState().studyReducer.stepData[currentStep].id;
    var accessCode = store.getState().studyReducer.accessCode;
    var participantId = store.getState().studyReducer.participantId;
    var studyId = store.getState().studyReducer.studyId;

    var stepPayload = {
      access_code: accessCode,
      participant_id: participantId,
      step_id: stepId
    }
    fetch(process.env.REACT_APP_API_URL + "/participation/studies/" + studyId + "/complete-step/", {
      method: 'POST',
      body: JSON.stringify(stepPayload)
    }).then(function(response) {
      if (response.ok) {
        // TODO: Currently not working for all steps, sort this out!
        return response.json();
      } else {
        let e = new Error(response.statusText);
        e.name = response.status || "";
        throw e
      }
    }).then(function() {
      PanelistActionController.panelistAction("LS:CompleteStep" + stepId);
      callback();
    }).catch(function(error) {
      ErrorHandler(error)
    })
  }

  static submitQuestionStep() {
    var currentStep = store.getState().studyReducer.currentStepOrderId;
    var stepData = store.getState().studyReducer.stepData;
    var stepId = store.getState().studyReducer.stepData[currentStep].id;
    var accessCode = store.getState().studyReducer.accessCode;
    var participantId = store.getState().studyReducer.participantId;
    var studyId = store.getState().studyReducer.studyId;

    const form = document.querySelector('#ls-questions');
    const formData = new Object;

    for (var pair of new FormData(form).entries()) {
      if (pair[0] in formData) {
        formData[pair[0]].push(pair[1])
      } else {
        formData[pair[0]] = [pair[1]]
      }
    }

    var questionPayload = {
      access_code: accessCode,
      participant_id: participantId,
      question_answer_list: processFormData(formData)
    }

    var stepPayload = {
      access_code: accessCode,
      participant_id: participantId,
      step_id: stepId
    }

    console.log(questionPayload);

    store.dispatch(progressAvailableFalse());

    fetch(process.env.REACT_APP_API_URL + "/participation/studies/" + studyId + "/question-response/", {
      method: 'POST',
      body: JSON.stringify(questionPayload)
    }).then(function(response) {
      if (response.ok) {
        return response.json();
      } else {
        let e = new Error(response.statusText);
        e.name = response.status || "";
        throw e
      }
    }).then(function(response) {
      if (!response.success) {
        let e = new Error(response.error);
        e.name = response.error_code || "";
        throw e
      } else {
        return fetch(process.env.REACT_APP_API_URL + "/participation/studies/" + studyId + "/complete-step/", {
          method: 'POST',
          body: JSON.stringify(stepPayload)
        })
      }
    }).then(function(response) {
      if (response.ok) {
        return response.json();
      } else {
        let e = new Error(response.statusText);
        e.name = response.status || "";
        throw e
      }
    }).then(function() {
      PanelistActionController.panelistAction("LS:CompleteStep" + stepId);
      if (currentStep === stepData.length -1) {
        store.dispatch(progressState());
        store.dispatch(submissionInProgressFalse());
      } else {
        store.dispatch(incrementStudyStep());
        store.dispatch(submissionInProgressFalse());
      }
    }).catch(function(error) {
      ErrorHandler(error)
    })
  }
}

function processFormData(formData) {
  let currentStep = store.getState().studyReducer.currentStepOrderId;
  let questionData = store.getState().studyReducer.stepData[currentStep].question_data;

  let answerArr = [];

  for (let [key, value] of Object.entries(formData)) {
    questionData.forEach(function(question) {
      let qTypeId = question.type_id;

      let a_formatter = (qTypeId) => {
        switch (qTypeId) {
          case 1:
          case 2:
            return 1
          case 3:
          case 4:
            return 2
          default:
            return 0
        }
      }

      const a_format = a_formatter(qTypeId);

      if (question.id === parseInt(key)) {
        if(value.length == 1 && value[0].includes(";")) {
          let splitVals = value[0].split(";");
          let sortedQuestionAnswers = question.answers.sort(function(a, b) {
            return a.position - b.position
          })
          splitVals.forEach(function(answerVal, index){
            answerArr.push({
              question_implementation_id: question.question_implementation_id,
              question_id: parseInt(key),
              answer_id: a_format === 1 ? answerVal : sortedQuestionAnswers[index].id,
              text: a_format === 2 ? answerVal : null
            });
          })
        } else {
          value.forEach(function(answerVal){
            answerArr.push({
              question_implementation_id: question.question_implementation_id,
              question_id: parseInt(key),
              answer_id: a_format === 1 ? answerVal : null,
              text: a_format === 2 ? answerVal : null
            });
          })
        }
      }
    })
  }

  return answerArr;
}
