import React from 'react';
import PreStartContent from '../../templates/prestartContent'
import IndexContent from '../../templates/indexContent'
import InstructionsContent from '../../templates/instructionsContent'
import SetupContent from '../../templates/setupContent'
import StudyContent from '../../templates/studyContent'
import CompletionContent from '../../templates/completionContent'
import ErrorContent from '../../templates/errorContent'
import InvalidContent from '../../templates/invalidContent'
import PreviewContent from '../../templates/previewContent'
import { store } from "../../../store/index";

/**
 * Component that renders content in the main area of the app.
 */
export default class LSTemplateRenderer extends React.Component {
  renderTemplate() {
    const TEMPLATE_STATES = {
      prestart: <PreStartContent store={store}/>,
      index: <IndexContent store={store}
              accessCode={this.props.accessCode}
              participant={this.props.participant}
              studyId={this.props.studyId} />,
      instructions: <InstructionsContent store={store} />,
      setup: <SetupContent
              store={store}
              studyReducer={this.props.studyReducer} />,
      study: <StudyContent/>,
      preview: <PreviewContent />,
      completion: <CompletionContent
                  store={store}
                  accessCode={this.props.accessCode}
                  participantId={this.props.participantId}
                  participant={this.props.participant}
                  studyId={this.props.studyId}
                  redirectUrlMemberInsertParameter={this.props.redirectUrlMemberInsertParameter}
                  successRedirectUrl={this.props.successRedirectUrl}
                  studyReducer={this.props.studyReducer} />,
      error: <ErrorContent
              store={store}
              errorMessage={this.props.errorMessage}
              errorMessageId={this.props.errorMessageId}
              accessCode={this.props.accessCode}
              participant={this.props.participant}
              studyId={this.props.studyId}
              quotaFullRedirectUrl={this.props.quotaFullRedirectUrl} />,
      invalid: <InvalidContent
        store={store}
        errorMessage={this.props.errorMessage}
        errorMessageId={this.props.errorMessageId}
        accessCode={this.props.accessCode}
        participant={this.props.participant}
        studyId={this.props.studyId}
        redirectUrlMemberInsertParameter={this.props.redirectUrlMemberInsertParameter}
        screenoutRedirectUrl={this.props.screenoutRedirectUrl} />
    }
    return TEMPLATE_STATES[this.props.basestate]
  }

  render() {
    return (
      this.renderTemplate()
    );
  }
}
