import React from 'react';
//import {FormattedMessage} from 'react-intl';
import LSIntlFormat from './intlFormat';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { store } from "../../store/index";

/**
 * Loading component.
 */
export default class LSLoading extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    console.log(store.getState()._persist.rehydrated)
    return (
      <CSSTransitionGroup transitionName="toggle-fade" transitionEnterTimeout={300} transitionLeaveTimeout={300}>
        <div className="absoluteCenter centerAlign ls-loading">
          <div className="lds-css">
            <div className="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
          { store.getState()._persist.rehydrated ? <h1>{LSIntlFormat.IntlMessageFormat("app.prestart.loading", "Loading, please wait.")}</h1> : null }
        </div>
    </CSSTransitionGroup>
  );
  }
}
