import React from 'react';
import {connect} from 'react-redux';
import {LSHelpTag} from '../helpTag'
import {changeHelpTag, toggleHelpModal} from '../actions'
import {toggleFaqModal} from '../../modal/actions'

// var modalContent = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sed imperdiet nisi. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Quisque placerat mi vel venenatis cursus. Nunc hendrerit orci a porttitor faucibus. Proin fermentum quam eu ex feugiat, at viverra nulla tempus. Proin et dui consequat tortor tristique convallis eget non nulla. Vivamus ullamcorper urna in sodales gravida."

function LSHelpTagCont(props) {
  return (<LSHelpTag toggleFaqModal={props.toggleFaqModal} enableHelpTag={props.enableHelpTag} disableHelpTag={props.disableHelpTag} helpTagActive={props.helpTagActive} displayHelpModal={props.displayHelpModal} toggleHelpModal={props.toggleHelpModal}/>)
}

const mapStateToProps = function(state) {
  return {helpTagActive: state.helpTagReducer.helpTagActive, displayHelpModal: state.helpTagReducer.displayHelpModal}
}

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    enableHelpTag: () => dispatch(changeHelpTag(true)),
    disableHelpTag: () => dispatch(changeHelpTag(false)),
    toggleHelpModal: () => dispatch(toggleHelpModal()),
    toggleFaqModal: () => dispatch(toggleFaqModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LSHelpTagCont);
