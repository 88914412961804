import React from 'react';
import {connect} from 'react-redux';
import LSStudyStep from '../studyStep'
import { progressState, progressAvailableTrue, progressAvailableFalse, errorState } from '../../../reducers/actions';
import { trackingStartTime } from '../../lumenController/actions';

function LSStudyStepCont(props) {
  return (
    <LSStudyStep
      validationElementRef={props.validationElementRef}
      calibrationElementRef={props.calibrationElementRef}
      headPositionElementRef={props.headPositionElementRef}
      errorMessage={props.errorMessage}
      errorMessageId={props.errorMessageId}
      submissionInProgress={props.submissionInProgress}
      progressState={props.progressState}
      progressAvailableTrue={props.progressAvailableTrue}
      progressAvailableFalse={props.progressAvailableFalse}
      currentStepId={props.currentStepId}
      currentStepOrderId={props.currentStepOrderId}
      stepData={props.stepData}
      et={props.et}
      errorState={props.errorState}
      studyReducer={props.studyReducer}
      selectedLanguage={props.selectedLanguage}
      basestate={props.basestate}
      trackingStartTime={props.trackingStartTime}
      showStimulusQuestion={props.showStimulusQuestion} />
  )
}

const mapStateToProps = function(state) {
  return {
    basestate: state.baseReducer.basestate,
    errorMessage: state.baseReducer.errorMessage,
    errorMessageId: state.baseReducer.errorMessageId,
    submissionInProgress: state.baseReducer.submissionInProgress,
    currentStepId: state.studyReducer.currentStepId,
    currentStepOrderId: state.studyReducer.currentStepOrderId,
    stepData: state.studyReducer.stepData,
    et: state.studyReducer.et,
    studyReducer: state.studyReducer,
    selectedLanguage: state.modalReducer.selectedLanguage,
    showStimulusQuestion: state.studyReducer.showStimulusQuestion
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    progressState: () => dispatch(progressState()),
    progressAvailableTrue: () => dispatch(progressAvailableTrue()),
    progressAvailableFalse: () => dispatch(progressAvailableFalse()),
    errorState: () => dispatch(errorState()),
    trackingStartTime: (payload) => dispatch(trackingStartTime(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LSStudyStepCont);
