import {combineReducers} from 'redux';
import modalReducer from '../components/modal/reducer'
import helpTagReducer from '../components/helpTag/reducer'
import menuReducer from '../components/menu/reducer'
import studyReducer from '../components/lumenController/reducer'
import baseReducer from './baseReducer'
import storage from 'redux-persist/lib/storage';

/**
 * This file is for combining reducers from different components across the whole application.
 */
const appReducer = combineReducers({baseReducer, modalReducer, helpTagReducer, menuReducer, studyReducer});

const rootReducer = (state, action) => {
  if (action.type === 'HARD_RESET') {
    storage.removeItem('persist:root')
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer;
