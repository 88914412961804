/**
 * Initial states for the modal reducer. The initial states refer to the
 * display state of each modal in the menu, and what the current selected
 * language is.
 */
const initialState = {
  // modals
  displayFaqModal: false,
  displayTermsModal: false,
  displayPrivacyModal: false,
  displayContactModal: false,
  displayTakePartModal: false,
  displayLanguageModal: false,
  selectedLanguage: "en"
};

/**
 * Initial the toggle cases display and hide the menu modals. The select
 * language case switches the language to the designated payload. Currently
 * available languages are English and Danish.
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'TOGGLE_FAQ_MODAL':
      return {
        ...state,
        displayFaqModal: !state.displayFaqModal
      }
    case 'TOGGLE_TERMS_MODAL':
      return {
        ...state,
        displayTermsModal: !state.displayTermsModal
      }
    case 'TOGGLE_PRIVACY_MODAL':
      return {
        ...state,
        displayPrivacyModal: !state.displayPrivacyModal
      }
    case 'TOGGLE_CONTACT_MODAL':
      return {
        ...state,
        displayContactModal: !state.displayContactModal
      }
    case 'TOGGLE_TAKE_PART_MODAL':
      return {
        ...state,
        displayTakePartModal: !state.displayTakePartModal
      }
    case 'TOGGLE_LANGUAGE_MODAL':
      return {
        ...state,
        displayLanguageModal: !state.displayLanguageModal
      }
    case 'SELECT_LANGUAGE':
      return {
        ...state,
        selectedLanguage: action.payload
      }
    default:
      return state
  }
};
