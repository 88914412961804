import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';
import LSStudyStepCont from 'components/studyController/containers/studyStepCont';
import PanelistActionController from '../../components/util/panelistActionController';

/**
 * Study template.
 */
export default class PreviewContent extends React.Component {
  constructor(props) {
    super(props);

    this.validationElementRef = React.createRef();
  }

  render() {
    return (<Container className="studyCont">
      <Row>
        <Col xs={12}>
          <div className="ls-main">
            <LSStudyStepCont validationElementRef={this.validationElementRef} />
          </div>
        </Col>
      </Row>
      <div id="validationElement" ref={this.validationElementRef}></div>
    </Container>);
  }
}
