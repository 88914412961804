import React from 'react';
import { connect } from 'react-redux';
import LSFooter from '../footerBar';
import { progressState, progressAvailableTrue, progressAvailableFalse, errorState } from '../../../../reducers/actions';
import { startResponseUpdate, assignResponseUpdate, sessionIdUpdate } from '../../../../components/lumenController/actions';

function FooterBarCont(props) {
  return (
    <LSFooter
      progressState={props.progressState}
      basestate={props.basestate}
      studyContext={props.studyContext}
      errorState={props.errorState}
      startResponseUpdate={props.startResponseUpdate}
      assignResponseUpdate={props.assignResponseUpdate}
      sessionIdUpdate={props.sessionIdUpdate}
      progressAvailableTrue={props.progressAvailableTrue}
      progressAvailableFalse={props.progressAvailableFalse}
      progressAvailable={props.progressAvailable} />
  )
}

const mapStateToProps = function(state) {
  return {
    basestate: state.baseReducer.basestate,
    progressAvailable: state.baseReducer.progressAvailable,
    studyContext: state.studyReducer
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    progressState: () => dispatch(progressState()),
    progressAvailableTrue: () => dispatch(progressAvailableTrue()),
    progressAvailableFalse: () => dispatch(progressAvailableFalse()),
    startResponseUpdate: (payload) => dispatch(startResponseUpdate(payload)),
    assignResponseUpdate: (payload) => dispatch(assignResponseUpdate(payload)),
    sessionIdUpdate: (payload) => dispatch(sessionIdUpdate(payload)),
    errorState: (payload) => dispatch(errorState(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterBarCont);
