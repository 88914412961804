export const demo1 = [{
    "page_id": 4,
    "type": "ValidationStep",
    "id": 11,
    "order": 1
  },
  {
    "time_to_move_next_button_ms": 0,
    "type": "InstructionStep",
    "order": 2,
    "time_to_show_next_button_ms": 5000,
    "page_id": 1,
    "id": 22
  },
  {
    "stim_width": 640,
    "video_looped": false,
    "stim_server_url": "https://api.dev.lumen-research.com/v1.0/stimuli_server/5/?json=1",
    "time_to_move_next_button_ms": 0,
    "stim_height": 480,
    "video_autoplay": true,
    "order": 4,
    "frame_container": null,
    "stimulus_id": 5,
    "time_to_show_next_button_ms": 5000,
    "video_mute": false,
    "stim_url": "https://s3.eu-west-2.amazonaws.com/ctt-media/beagle-puppy-2681_640.jpg",
    "video_showcontrols": false,
    "video_fullscreen": false,
    "type": "StimulusStep",
    "id": 1
  },
  {
    "stim_width": 0,
    "video_looped": false,
    "stim_server_url": "https://api.dev.lumen-research.com/v1.0/stimuli_server/6/?json=1",
    "time_to_move_next_button_ms": 0,
    "stim_height": 0,
    "video_autoplay": true,
    "order": 5,
    "frame_container": null,
    "stimulus_id": 6,
    "time_to_show_next_button_ms": 5000,
    "video_mute": false,
    "stim_url": "https://s3.eu-west-2.amazonaws.com/ctt-media/cat-1192026_640.jpg",
    "video_showcontrols": false,
    "video_fullscreen": false,
    "type": "StimulusStep",
    "id": 6
  },
  {
    "page_id": 4,
    "type": "ValidationStep",
    "id": 12,
    "order": 6
  }
]
