import React from 'react';
import LSStudyInstructionStep from './steps/instructionStep';
import LSStudyValidationStep from '../../../components/studyController/steps/validationStep';
import LSStudyStimulusStep from './steps/stimulusStep';
import LSStudyEmbeddedStep from './steps/embeddedStep';
import LSStudyRedirectStep from './steps/redirectStep';
import LSStudyExternalStep from './steps/externalStep';

/**
 * Component that renders content for the study step.
 */
export default class LSStudyStep extends React.Component {
  componentDidMount() {
    this.props.progressAvailableFalse();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.currentStepOrderId !== this.props.currentStepOrderId
  }

  renderStep() {
    var currentStep = this.props.currentStepOrderId;
    var currentStepId = this.props.stepData[currentStep].id;
    switch(this.props.stepData[currentStep].type) {
      case 'InstructionStep':
        return <LSStudyInstructionStep {...this.props}/>

      case 'ValidationStep':
        return <LSStudyValidationStep {...this.props} />

      case 'StimulusStep':
        if (this.props.stepData[currentStep].frame_container !== null && this.props.stepData[currentStep].frame_container.includes("prodpicker")) {
          // TODO: Convert to different step type
          // TODO: Enable ProdPicker
          //return <LSStudyProdPickerStep {...this.props} />
          return <LSStudyStimulusStep {...this.props} />
        } else {
          return <LSStudyStimulusStep {...this.props} />
        }

      case "EmbeddedStep":
        if (this.props.stepData[currentStep].frame_container !== null) {
          return <LSStudyEmbeddedStep {...this.props} />
        } else {
          return <LSStudyRedirectStep {...this.props} />
        }

      case "CachePageStep":
        return <LSStudyRedirectStep {...this.props} />

      case "ExternalStep":
        return <LSStudyExternalStep {...this.props} />

      default:
        var result = <p>{this.props.currentStep}</p>
        return result
    }
  }

  render() {
    return (
      this.renderStep()
    );
  }
}
