import * as actions from "./action-types";

export function toggleHelpModal() {
  return {
    type: actions.TOGGLE_HELP_MODAL
  };
}

export function changeHelpTag(payload) {
  return {
    type: actions.CHANGE_HELP_TAG,
    payload
  };
}
