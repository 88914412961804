import React from 'react';
// DEMO ALT
import StudyNextController from 'components/studyController/studyNextController';
import { Player, ControlBar, PlayToggle, VolumeMenuButton } from 'video-react';
import ROLLBAR from '../../../rollbar.js';
import ErrorHandler from '../../util/errorHandler';
import {handleFullscreenOpen, handleFullscreenClose} from '../../util/fullscreenController';

export default class LSStudyProdPickerStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stimSrc: null,
      stimHtmlConstructorType: null,
      clickHandled: false
    }

    this.storedStepId = null;
    this.playerSubscribed = false;
    this.endHandled = false;
    this.imgStim = React.createRef();
    this.hiddenShelfImg = React.createRef();

    this.htmlConstructor = this.htmlConstructor.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.internalRender = this.internalRender.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
    this.aoiRenderer = this.aoiRenderer.bind(this);
  }

  clickHandler() {
    if (!this.state.clickHandled) {
      this.setState((state) => {
        return {
          ...state,
          clickHandled: true
        }
      })
      //handleFullscreenClose(true);
      window.removeEventListener('resize', this.handleResize);
      setTimeout(StudyNextController.callNext, 200);
    }
  }

  aoiRenderer(item, key) {
    var currentStep = this.props.currentStepOrderId;
    let stimWidth = this.props.stepData[currentStep].stim_width;
    let stimHeight = this.props.stepData[currentStep].stim_height;
    let aoi = item;
    let aoiStyle = {
      position: "absolute",
      left: ((aoi.geometry[0].x / stimWidth) * 100) + "%",
      top: ((aoi.geometry[0].y / stimHeight) * 100) + "%",
      width: (((aoi.geometry[1].x - aoi.geometry[0].x) / stimWidth) * 100) + "%",
      height: (((aoi.geometry[2].y - aoi.geometry[0].y) / stimHeight) * 100) + "%"
    }
    return <div onClick={this.clickHandler} className="ls-lumenShelfAoi" key={key} style={aoiStyle}></div>
  }

  htmlConstructor() {
    var currentStep = this.props.currentStepOrderId;
    let aoiArray = this.props.stepData[currentStep].features_list;
    switch (this.state.stimHtmlConstructorType) {
      case "img":
        return <>
                 <img id="lumenStim" alt="Lumen stim" ref={this.imgStim} src={this.state.stimSrc}></img>
                 <div className="ls-shelfCont">
                   <img className="ls-shelfHidden" alt="Lumen stim hidden" ref={this.hiddenShelfImg} style={{visibility: "hidden"}} src={this.state.stimSrc}></img>
                   {typeof aoiArray !== "undefined" && aoiArray.len !== 0 ? aoiArray.map(this.aoiRenderer) : null}
                 </div>
               </>
      case "video":
        // TODO: Test video
        this.playerSubscribed = false;
        this.endHandled = false;
        return <Player videoId="lumenStim" ref={(player) => { this.player = player }} autoPlay playsInline src={this.state.stimSrc}>
                <ControlBar autoHide={false} disableDefaultControls={true}>
                  <PlayToggle />
                  <VolumeMenuButton />
                </ControlBar>
               </Player>
      default:
        return null
    }
  }

  handleError(userId, success) {
    if (!success) {
      let e = new Error("Prod Picker Start Tracking Failure")
      ErrorHandler(e)
    }
  }

  internalRender() {
    var resultInternal = null;
    let componentRef = this;
    var currentStep = this.props.currentStepOrderId;
    var currentStepId = this.props.stepData[currentStep].id;
    var currentStepOrderId = this.props.stepData[currentStep].order;
    this.storedStepId = currentStep;
    fetch(this.props.stepData[currentStep].stim_server_url).then(function(response) {
      componentRef.resourceFetched = true;
      if (response.ok) {
        return response.json();
      } else {
        let e = new Error(response.statusText);
        e.name = response.status || "";
        throw e
      }
    }).then(function(response) {
      var parser = new DOMParser();
      var doc = parser.parseFromString(response.final_html, "text/html");
      resultInternal = doc.body.children[0].src;
      if (doc.body.children[0].tagName.toLowerCase() === "img") {
        componentRef.setState((state) => {
          return {
            ...state,
            stimSrc: resultInternal,
            stimHtmlConstructorType: "img"
          }
        })
      } else {
        componentRef.setState((state) => {
          return {
            ...state,
            stimSrc: resultInternal,
            stimHtmlConstructorType: "video"
          }
        })
      }
      if (componentRef.props.stepData[currentStep].time_to_move_next_button_ms > 0) {
        setTimeout(componentRef.clickHandler, componentRef.props.stepData[currentStep].time_to_move_next_button_ms)
      }
      componentRef.props.et.startTracking(componentRef.props.studyReducer.studyId, componentRef.props.studyReducer.participant, ["lumenStim"], componentRef.handleError, currentStepId, currentStepOrderId, componentRef.props.studyReducer.showGaze);
    }).catch(function(error) {
      ErrorHandler(error)
    })
  }

  handleResize() {
    this.hiddenShelfImg.current.style.height = this.imgStim.current.offsetHeight + "px";
  }

  componentDidMount() {
    this.internalRender();
    //handleFullscreenOpen(true);
  }

  handleEnded(state, prevState) {
    if ((state.currentTime == state.duration) && state.paused && this.endHandled === false) {
      this.endHandled = true;
      setTimeout(StudyNextController.callNext, 1000);
    }
  }

  componentDidUpdate() {
    let componentRef = this;
    if (this.props.currentStepOrderId !== this.storedStepId) {
      this.internalRender();
    }
    let apply
    if (this.imgStim.current) {
      if(this.imgStim.current.offsetHeight === 0) {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        let checkForAppropriateSize = () => {
          if (componentRef.imgStim.current.offsetHeight > 0) {
            componentRef.handleResize();
          } else {
            setTimeout(checkForAppropriateSize, 100);
          }
        }
        checkForAppropriateSize();
      } else {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
      }
    }
    setTimeout(() => {
      if (typeof this.player !== 'undefined' && this.playerSubscribed !== true) {
        this.playerSubscribed = true;
        var currentStep = this.props.currentStepOrderId;
        if (this.props.stepData[currentStep].time_to_show_next_button_ms < 0 || this.props.stepData[currentStep].time_to_move_next_button_ms < 0) {
          this.player.subscribeToStateChange(this.handleEnded.bind(this));
        }
      }
    }, 1000)
  }

  render() {
    // TODO (Translation): Below h3 tags need to be translated into FR and DE
    return (
      <React.Fragment>
        {this.htmlConstructor()}
      </React.Fragment>
    );
  }
}
