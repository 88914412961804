import { assignResponseUpdate } from '../../../components/lumenController/actions';
import { errorState, changeStateTo } from '../../../reducers/actions';
import {store} from "../../../store/index";
import ROLLBAR from '../../../rollbar.js'

export const onBeforeLift = () => {
  return new Promise((resolve, reject) => {
    const urlParams = new URLSearchParams(window.location.search);
    var studyId = urlParams.get('study_id');
    var cellId = urlParams.get('cell_id');
    var stepId = urlParams.get('step_id');

    function resolveCB(userId, success, errorObj = null) {
      if (!success) {
        store.dispatch(errorState(errorObj));
        resolve();
      } else {
        resolve();
      }
    }

    store.dispatch({
      type: "HARD_RESET"
    });
    if (!!studyId && !!cellId && !!stepId) {
      fetch(process.env.REACT_APP_API_URL + '/participation/studies/' + studyId + '/preview', {
        method: 'POST',
        body: JSON.stringify({
          cell_id: cellId,
          step_id: stepId
        })
      }).then(function(response) {
        if (response.ok) {
          return response.json();
        } else {
          throw response.status + " " + response.statusText;
        }
      }).then(function(response) {
        if (!response.success) {
          throw response.status + " " + response.statusText;
        } else {
          var assignPayload = {
            cellStatus: null,
            cellId: null,
            participantDetails: null,
            participantStatus: null,
            participantId: null,
            stepData: [response.step_data]
          }
          store.dispatch(assignResponseUpdate(assignPayload));
          store.dispatch(changeStateTo("preview"));
          console.log("changing to step")
          resolveCB(store.getState().studyReducer.participant, true);
        }
      }).catch(function(error) {
        if (!!error.errorMessage) {
          ROLLBAR.error(error.errorMessage)
        } else if (typeof error === 'object') {
          ROLLBAR.error(error.message)
        } else {
          ROLLBAR.error(error)
        }
        resolveCB(store.getState().studyReducer.participant, false);
      })
    } else {
      resolve();
    }
  })
}
