import React from 'react';
import {FormattedMessage} from 'react-intl';
import {progressState, errorState} from '../../reducers/actions';
import {panelResponseUpdate, noPanelStudyUpdate} from '../../components/lumenController/actions';
import {selectLanguage} from '../../components/modal/actions'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import ROLLBAR from '../../rollbar.js'

/**
 * Prestart template.
 */
export default class PreStartContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {finishPreload: false}

    this.preloadFinished = this.preloadFinished.bind(this);
    this.renderAnimation = this.renderAnimation.bind(this);
  }

  preloadFinished() {
    this.setState((state) => {
      return {finishPreload: true}
    })
  }

  /**
  * TODO: handle preload
  */
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    var demoId = urlParams.get('demo_id');
    var showGaze = urlParams.get('show_gaze');
    var lang = urlParams.get('lang');

    if(!!lang) {
      this.props.store.dispatch(selectLanguage(lang));
    }

    var noPanelPayload = {
      cellId: null,
      studyId: demoId,
      accessCode: null,
      participant: null,
      showGaze: !!showGaze,
      test: true
    }
    this.props.store.dispatch(noPanelStudyUpdate(noPanelPayload));
    setTimeout(() => { this.preloadFinished() }, 1000);
    setTimeout(() => this.props.store.dispatch(progressState()), 1500)
  }

  renderAnimation() {
    return !this.state.finishPreload ? <div className="absoluteCenter centerAlign">
      <div className="lds-css">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
      <h1><FormattedMessage id="app.prestart.loading" defaultMessage="Loading, please wait." description="Loading text"/></h1>
    </div> : null
  }

  render() {
    return (
      <CSSTransitionGroup transitionName="toggle-fade" transitionEnterTimeout={300} transitionLeaveTimeout={300}>
      { this.renderAnimation() }
    </CSSTransitionGroup>
  );
  }
}
