import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button';
import {FormattedMessage} from 'react-intl';
import Slider from "react-slick";
import glassesImg from '../../images/glasses.png';
import manImg from '../../images/man.png';
import focusImg from '../../images/focus.png';
import rotatePhone from '../../images/phonerotate.png';
import wifi from '../../images/wifi.svg';
import eyesMove from '../../images/focus.svg';
import { progressAvailableTrue, progressAvailableFalse, progressState } from '../../reducers/actions'
import getDeviceInfo from '../../components/util/deviceValidation'

/**
 * Instructions template.
 */
export default class InstructionsContent extends React.Component {
  constructor(props) {
    super(props);
    this.allSlides = null;
    this.enableProgress = this.enableProgress.bind(this);
  }

  enableProgress(currentSlide) {
    if (currentSlide === this.allSlides - 1) {
      this.props.store.dispatch(progressAvailableTrue())
    } else {
      // Can be made more efficient - check if already disabled
      this.props.store.dispatch(progressAvailableFalse())
    }
  }

  componentDidMount() {
    this.props.store.dispatch(progressAvailableFalse());
    this.allSlides = document.querySelectorAll(".slick-slide").length;
  }

  render() {
    var sliderSettings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      afterChange: this.enableProgress,
      responsive: [
        {
          breakpoint: 50000,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    const DEVICE_INFO = getDeviceInfo();
    return (<Container>
      <Row>
        <Col xs={12}>
          <div className="ls-main">
            <h1>Instructions</h1>
            <Slider {...sliderSettings}>
              <div>
                <h3 style={{marginTop: "110px"}}><FormattedMessage id="app.instructions.instruction6" defaultMessage="Please read the following instructions carefully." description="Instruction 6"/></h3>
              </div>
            <div>
              <img alt="Glasses" className="sliderImg" src={glassesImg} />
              <h4><FormattedMessage id="app.instructions.instruction1" defaultMessage="If practical, please remove your glasses." description="Instruction 1"/></h4>
            </div>
            <div>
              <img alt="Silhouette of man" className="sliderImg" src={manImg} />
              <h4><FormattedMessage id="app.instructions.instruction2" defaultMessage="Once your face is detected, please keep your head still." description="Instruction 2"/></h4>
            </div>
            <div>
              <img alt="Focus" className="sliderImg" src={eyesMove} />
              <h4><FormattedMessage id="app.instructions.instruction3" defaultMessage="Move your eyes not your head." description="Instruction 3"/></h4>
            </div>
            {/* TODO: Can be optimised here */}
            { DEVICE_INFO.isMobile ? <div>
              <img alt="Phone rotating" className="sliderImg" src={rotatePhone} />
              <h4><FormattedMessage id="app.setup.instruction0" defaultMessage="Please rotate your device to portrait mode." description="Instruction 0"/></h4>
            </div> : null }
            { DEVICE_INFO.isMobile ? <div>
              <img alt="Wifi Symbol" className="sliderImg" src={wifi} />
              <h4><FormattedMessage id="app.instructions.instruction5" defaultMessage="Please use wifi to avoid mobile data charges." description="Instruction 5"/></h4>
            </div> : null }
            <div>
              <h3 className="finalInstructionSlick"><FormattedMessage id="app.instructions.instruction4" defaultMessage="Ready to start?" description="Instruction 4"/></h3>
              <Button style={{marginTop: "20px"}} onClick={() => {this.props.store.dispatch(progressState())}} className="button-primary">
                <FormattedMessage id="app.button.start" defaultMessage="Start" description="Start Button"/>
              </Button>
            </div>
          </Slider>
          </div>
        </Col>
      </Row>
    </Container>);
  }
}
