import React from 'react';
import { connect } from 'react-redux';
import LSMenu from '../menu'
import { toggleFaqModal, toggleTermsModal, toggleContactModal, toggleLanguageModal, togglePrivacyModal } from '../../modal/actions'
import { toggleHelpModal } from '../../helpTag/actions'
import { toggleMenu } from '../actions'
import { debugState } from '../../../reducers/actions';

function LSMenuCont(props) {
  return (
    <LSMenu
      toggleMenu={props.toggleMenu}
      displayMenu={props.displayMenu}
      displayFaqModal={props.displayFaqModal}
      displayPrivacyModal={props.displayPrivacyModal}
      displayContactModal={props.displayContactModal}
      displayLanguageModal={props.displayLanguageModal}
      displayTermsModal={props.displayTermsModal}
      toggleFaqModal={props.toggleFaqModal}
      toggleTermsModal={props.toggleTermsModal}
      togglePrivacyModal={props.togglePrivacyModal}
      toggleContactModal={props.toggleContactModal}
      toggleLanguageModal={props.toggleLanguageModal}
      toggleHelpModal={props.toggleHelpModal}
      debugState={props.debugState}
      className={props.className} />
  )
}

const mapStateToProps = function(state) {
  return {
    displayFaqModal: state.modalReducer.displayFaqModal,
    displayPrivacyModal: state.modalReducer.displayPrivacyModal,
    displayContactModal: state.modalReducer.displayContactModal,
    displayLanguageModal: state.modalReducer.displayLanguageModal,
    displayTermsModal: state.modalReducer.displayTermsModal,
    displayMenu: state.menuReducer.displayMenu
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    toggleFaqModal: () => dispatch(toggleFaqModal()),
    toggleTermsModal: () => dispatch(toggleTermsModal()),
    togglePrivacyModal: () => dispatch(togglePrivacyModal()),
    toggleContactModal: () => dispatch(toggleContactModal()),
    toggleLanguageModal: () => dispatch(toggleLanguageModal()),
    toggleMenu: () => dispatch(toggleMenu()),
    toggleHelpModal: () => dispatch(toggleHelpModal()),
    debugState: () => dispatch(debugState())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LSMenuCont);
