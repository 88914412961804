import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import ROLLBAR from '../../../rollbar.js';
import thumbsUp from '../thumbsUp.png';
import progressTimer from '../progressTimer';
import ErrorHandler from '../../util/errorHandler';

function processRichtText(text){
    var bold = /\*\*(.*?)\*\*/gm;
    var italic = /\_(.*?)\_/gm;
    var html = text.replace(bold, '<b>$1</b>');
    var html = html.replace(italic, '<i>$1</i>')
    return html;
}

export default class LSStudyInstructionStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      instructionText: null,
      instructionImage: null,
    }

    this.storedStepId = null;

    this.internalRender = this.internalRender.bind(this);
  }

  internalRender() {
    let componentRef = this;
    var currentStep = this.props.currentStepOrderId;
    // var currentStepId = this.props.stepData[currentStep].id;
    var resultInternal = null;
    this.storedStepId = currentStep;
    fetch(process.env.REACT_APP_API_URL_NO_VER + "/task_pages/page_json/" + this.props.stepData[currentStep].page_id + "?language=" + componentRef.props.selectedLanguage).then(function(response) {
      if (response.ok) {
        return response.json();
      } else {
        let e = new Error(response.statusText);
        e.name = response.status || "";
        throw e
      }
    }).then(function(response) {
      resultInternal = !!response.content ? response.content : response.default_content;
      componentRef.setState((state) => {
        return {
          instructionText: processRichtText(resultInternal),
          instructionImage: !!response.image_src ? response.image_src : null
        }
      })
    }).catch(function(error) {
      ErrorHandler(error)
    })
    progressTimer(this.props.progressAvailableTrue, this.props.stepData, currentStep);
  }

  componentDidMount() {
    this.internalRender();
  }

  componentDidUpdate() {
    if (this.props.currentStepOrderId !== this.storedStepId) {
      this.internalRender();
    }
  }

  render() {
    return (
      <Row className="instructionRow">
        <Col xs={12} sm={9}><pre dangerouslySetInnerHTML={{__html: this.state.instructionText}}></pre></Col>
        <Col xs={12} sm={3} className="centerAlign"><img alt="Instruction Image" className={!!this.state.instructionImage ? "instructionImage" : "instructionImage instructionImageDefault"} src={!!this.state.instructionImage ? this.state.instructionImage : thumbsUp} /></Col>
      </Row>
    );
  }
}
