import { LogUserActionInternal } from '@lumen-developer/lumen-common-js';
import { store } from "../../store/index";

export default class PanelistActionController {
  static panelistAction(action, log = null) {

    var participant = store.getState().studyReducer.participant;
    var studyId = store.getState().studyReducer.studyId;
    var panelCode = store.getState().studyReducer.panelCompanyCode;

    if (!!log) {
      LogUserActionInternal(participant, studyId, action, {
        log: log
      }, panelCode);
    } else {
      LogUserActionInternal(participant, studyId, action, {}, panelCode);
    }

  }
}
