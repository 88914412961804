// DEMO ALT
import LumenEyeTracking from "components/lumenTracker/build.js";

const ET = LumenEyeTracking;

const initialState = {
  et: ET,
  eyetrackingEnabled: true,
  brokerDetails: null,
  studyId: null,
  vendorId: "71de884d-00a5-4caf-8c79-97f3dd0e064b",
  currentStepId: 0,
  lastStep: null,
  currentStepOrderId: 0,
  studyName: "",
  panelCompanyCode: "",
  successRedirectUrl: null,
  screenoutRedirectUrl: null,
  quotaFullRedirectUrl: null,
  redirectUrlMemberInsertParameter: null,
  secondsBeforeMoveNext: 5,
  accessCode: "",
  participant: "",
  cleanedParticipantReference: null,
  test: false,
  sessionId: null,
  external_reference: null,
  demographicSurveyUrl: null,
  demographicSurveyUrlMemberInsertParameter: null,
  demographicsSurveyQuestionsInternal: [],
  demographicsSurveyResponsesInternal: {},
  cellStatus: null,
  cellId: null,
  participantDetails: null,
  participantStatus: null,
  participantId: null,
  showGaze: false,
  stepData: {},
  trackingStartTime: null,
  orientation: 1,
  requireChromeExtension: false,
  fullScreenRequired: false,
  hasExternalDomain: false,
  showStimulusQuestion: false,
  calibrationInfo: {},
}

/**
 * Initial states for the modal reducer. The initial states refer to the
 * display state of each modal in the menu, and what the current selected
 * language is.
 */

/**
 * Initial the toggle cases display and hide the menu modals. The select
 * language case switches the language to the designated payload. Currently
 * available languages are English and Danish.
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'INCREMENT_STUDY_STEP':
      return {
        ...state,
        currentStepId: state.stepData[state.currentStepOrderId + 1].id,
        currentStepOrderId: state.currentStepOrderId + 1
      }
    case 'DECREMENT_STUDY_STEP':
      return {
        ...state,
        currentStepId: state.stepData[state.currentStepOrderId - 1].id,
        currentStepOrderId: state.currentStepOrderId - 1
      }
    case 'RESET_STUDY_STEP_DEBUG':
      return {
        ...state,
        currentStepId: state.stepData[0].id,
        currentStepOrderId: 0
      }
    case 'SESSION_ID_UPDATE':
      return {
        ...state,
        sessionId: action.payload.sessionId
      }
    case 'NO_PANEL_STUDY_UPDATE':
      return {
        ...state,
        studyId: action.payload.studyId,
        cellId: action.payload.cellId,
        accessCode: action.payload.accessCode,
        participant: action.payload.participant,
        showGaze: action.payload.showGaze,
        test: action.payload.test
      }
    case 'PANEL_RESPONSE_UPDATE':
      return {
        ...state,
        panelCompanyCode: action.payload.panelCompanyCode,
        successRedirectUrl: action.payload.successRedirectUrl,
        screenoutRedirectUrl: action.payload.screenoutRedirectUrl,
        quotaFullRedirectUrl: action.payload.quotaFullRedirectUrl,
        redirectUrlMemberInsertParameter: action.payload.redirectUrlMemberInsertParameter,
        studyId: action.payload.studyId,
        cellId: action.payload.cellId,
        studyName: action.payload.studyName,
        secondsBeforeMoveNext: action.payload.secondsBeforeMoveNext,
        accessCode: action.payload.accessCode,
        participant: action.payload.participant,
        showGaze: action.payload.showGaze,
        test: action.payload.test,
        eyetrackingEnabled: action.payload.eyetrackingEnabled
      }
    case 'START_RESPONSE_UPDATE':
      return {
        ...state,
        brokerDetails: action.payload.brokerDetails,
        cleanedParticipantReference: action.payload.cleanedParticipantReference,
        participant: action.payload.cleanedParticipantReference,
        demographicSurveyUrl: action.payload.demographicSurveyUrl,
        demographicSurveyUrlMemberInsertParameter: action.payload.demographicSurveyUrlMemberInsertParameter,
        orientation: action.payload.orientation,
        requireChromeExtension: action.payload.requireChromeExtension
      }
    case 'ASSIGN_RESPONSE_UPDATE':
      return {
        ...state,
        cellStatus: action.payload.cellStatus,
        cellId: action.payload.cellId,
        participantDetails: action.payload.participantDetails,
        participantStatus: action.payload.participantStatus,
        participantId: action.payload.participantId,
        stepData: action.payload.stepData,
        fullScreenRequired: action.payload.fullScreenRequired,
        hasExternalDomain: action.payload.hasExternalDomain
      }
    case 'TRACKING_START_TIME':
      return {
        ...state,
        trackingStartTime: action.payload
      }
    case 'SHOW_STIMULUS_QUESTION':
      return {
        ...state,
        showStimulusQuestion: true
      }
    case 'HIDE_STIMULUS_QUESTION':
      return {
        ...state,
        showStimulusQuestion: false
      }
    case 'UPDATE_INTERNAL_DEMOGRAPHICS_QUESTIONS':
      return {
        ...state,
        demographicsSurveyQuestionsInternal: action.payload,
      }
    case 'UPDATE_INTERNAL_DEMOGRAPHICS_RESPONSES':
      return {
        ...state,
        demographicsSurveyResponsesInternal: action.payload
      }
    case 'CALIBRATION_INFO':
      return {
        ...state,
        calibrationInfo: action.payload
      }
    default:
      return state
  }
};
