import * as actions from "./action-types";

export function incrementStudyStep() {
  return {
    type: actions.INCREMENT_STUDY_STEP
  };
}

export function sessionIdUpdate(payload) {
  return {
    type: actions.SESSION_ID_UPDATE,
    payload: payload
  }
}

export function noPanelStudyUpdate(payload) {
  return {
    type: actions.NO_PANEL_STUDY_UPDATE,
    payload: payload
  }
}

export function panelResponseUpdate(payload) {
  return {
    type: actions.PANEL_RESPONSE_UPDATE,
    payload: payload
  };
}

export function startResponseUpdate(payload) {
  return {
    type: actions.START_RESPONSE_UPDATE,
    payload: payload
  }
}

export function assignResponseUpdate(payload) {
  return {
    type: actions.ASSIGN_RESPONSE_UPDATE,
    payload: payload
  }
}

export function trackingStartTime(payload) {
  return {
    type: actions.TRACKING_START_TIME,
    payload: payload
  }
}

export function showStimulusQuestion() {
  return {
    type: actions.SHOW_STIMULUS_QUESTION,
  }
}

export function hideStimulusQuestion() {
  return {
    type: actions.HIDE_STIMULUS_QUESTION,
  }
}

export function updateInternalDemographicsQuestions(payload) {
  return {
    type: actions.UPDATE_INTERNAL_DEMOGRAPHICS_QUESTIONS,
    payload: payload,
  }
}

export function updateInternalDemographicsResponses(payload) {
  return {
    type: actions.UPDATE_INTERNAL_DEMOGRAPHICS_RESPONSES,
    payload: payload,
  }
}

export function calibrationInfo(payload) {
  return {
    type: actions.CALIBRATION_INFO,
    payload: payload,
  }
}
