import React, {Component} from 'react';
import './App.css';
import '../themes/currentTheme';
import "../../node_modules/video-react/dist/video-react.css";
import LSHeaderCont from '../components/header/containers/headerCont'
// DEMO ALT
import FooterBarCont from 'components/footerBar/containers/footerBarCont'
// DEMO ALT
import LSTemplateRendererCont from 'components/templateRenderer/containers/templateRendererCont'
import {IntlProvider} from "react-intl";
import {addLocaleData} from "react-intl";
import locale_en from 'react-intl/locale-data/en';
import locale_de from 'react-intl/locale-data/de';
import locale_fr from 'react-intl/locale-data/fr';
import locale_ja from 'react-intl/locale-data/ja';
import locale_it from 'react-intl/locale-data/it';
import locale_es from 'react-intl/locale-data/es';
import locale_nl from 'react-intl/locale-data/nl';
import messages_en from "../translations/en.json";
import messages_de from "../translations/de.json";
import messages_fr from "../translations/fr.json";
import messages_ja from "../translations/ja.json";
import messages_it from "../translations/it.json";
import messages_es from "../translations/es.json";
import messages_nl from "../translations/nl.json";

var WebFont = require('webfontloader');

WebFont.load({
  google: {
    families: ['Source Sans Pro:400,600,700,900']
  }
});

addLocaleData([
  ...locale_en,
  ...locale_de,
  ...locale_fr,
  ...locale_ja,
  ...locale_it,
  ...locale_es,
  ...locale_nl
]);

const messages = {
  'en': messages_en,
  'de': messages_de,
  'fr': messages_fr,
  'ja': messages_ja,
  'it': messages_it,
  'es': messages_es,
  'nl': messages_nl
};

/**
 * Primary component.
 */
class App extends Component {
  render() {
    let classConstruct = "ls " + this.props.basestate;
    return (
      <IntlProvider locale={this.props.selectedLanguage} messages={messages[this.props.selectedLanguage]}>
        <div className={classConstruct}>
          <LSHeaderCont />
          <LSTemplateRendererCont/>
          <FooterBarCont/>
        </div>
      </IntlProvider>
    );
  }
}

export default App;
