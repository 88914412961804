import React from 'react';
import LSStudyInstructionStep from './steps/instructionStep';
import LSStudyValidationStep from './steps/validationStep';
import LSStudyStimulusStep from './steps/stimulusStep';
import LSStudyEmbeddedStep from './steps/embeddedStep';
import LSStudyRedirectStep from './steps/redirectStep';
import LSStudyExternalStep from './steps/externalStep';
import LSStudyProdPickerStep from './steps/prodPickerStep';
import LSZoomStep from './steps/zoomStep';
import LSStudyRecalibrateStep from './steps/recalibrateStep';
import LSQuestionStep from './steps/questionStep';
import getDeviceInfo from '../util/deviceValidation';

/**
 * Component that renders content for the study step.
 */
export default class LSStudyStep extends React.Component {
  componentDidMount() {
    this.props.progressAvailableFalse();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.currentStepOrderId !== this.props.currentStepOrderId ||
    nextProps.submissionInProgress !== this.props.submissionInProgress ||
    nextProps.showStimulusQuestion !== this.props.showStimulusQuestion
  }

  renderStep() {
    var currentStep = this.props.currentStepOrderId;
    // var currentStepId = this.props.stepData[currentStep].id;
    switch(this.props.stepData[currentStep].type) {
      case 'InstructionStep':
        return <LSStudyInstructionStep {...this.props}/>

      case 'ValidationStep':
        return <LSStudyValidationStep {...this.props} />

      case 'StimulusStep':
        // Handle shelf type
        if (this.props.stepData[currentStep].stim_implementation_type === 4) {
          return <LSStudyProdPickerStep {...this.props} />
          // Handle zoom type
        } else if (this.props.stepData[currentStep].stim_implementation_type === 2 && !getDeviceInfo().isMobile) {
          return <LSZoomStep {...this.props} />
        } else {
          return <LSStudyStimulusStep {...this.props} />
        }

      case "EmbeddedStep":
        if (this.props.stepData[currentStep].frame_container !== null) {
          return <LSStudyEmbeddedStep {...this.props} />
        } else {
          return <LSStudyRedirectStep {...this.props} />
        }

      case "CachePageStep":
        return <LSStudyRedirectStep {...this.props} />

      case "ExternalStep":
        return <LSStudyExternalStep {...this.props} />

      case "CalibrationStep":
        return <LSStudyRecalibrateStep {...this.props} />

      case "QuestionStep":
        return <LSQuestionStep {...this.props} />

      // case "StimulusQuestionStep":
      //   if (this.props.showStimulusQuestion) {
      //     return <LSQuestionStep {...this.props} />
      //   } else {
      //     return <LSStudyStimulusStep {...this.props} />
      //   }

      default:
        var result = <p>{this.props.currentStep}</p>
        return result
    }
  }

  render() {
    return (
      this.renderStep()
    );
  }
}
