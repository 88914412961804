export const INCREMENT_STUDY_STEP = "INCREMENT_STUDY_STEP";
export const SESSION_ID_UPDATE = 'SESSION_ID_UPDATE';
export const NO_PANEL_STUDY_UPDATE = "NO_PANEL_STUDY_UPDATE";
export const PANEL_RESPONSE_UPDATE = "PANEL_RESPONSE_UPDATE";
export const START_RESPONSE_UPDATE = "START_RESPONSE_UPDATE";
export const ASSIGN_RESPONSE_UPDATE = "ASSIGN_RESPONSE_UPDATE";
export const TRACKING_START_TIME = "TRACKING_START_TIME";
export const SHOW_STIMULUS_QUESTION = "SHOW_STIMULUS_QUESTION";
export const HIDE_STIMULUS_QUESTION = "HIDE_STIMULUS_QUESTION";
export const UPDATE_INTERNAL_DEMOGRAPHICS_QUESTIONS = "UPDATE_INTERNAL_DEMOGRAPHICS_QUESTIONS";
export const UPDATE_INTERNAL_DEMOGRAPHICS_RESPONSES = "UPDATE_INTERNAL_DEMOGRAPHICS_RESPONSES";
export const CALIBRATION_INFO = "CALIBRATION_INFO";
