const initialState = {
  // modals
  displayMenu: false
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'TOGGLE_MENU':
      return {
        ...state,
        displayMenu: !state.displayMenu
      }
    default:
      return state
  }
};
