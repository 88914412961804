// DEMO ALT
import StudyNextController from 'components/studyController/studyNextController';

export default function progressTimer(progressAvailableTrue, stepData, currentStep, customTimer = null) {
  if(stepData[currentStep].time_to_move_next_button_ms === 0 || !!!stepData[currentStep].time_to_move_next_button_ms) {
    setTimeout(progressAvailableTrue, (!!customTimer ? customTimer : stepData[currentStep].time_to_show_next_button_ms))
  } else {
    setTimeout(StudyNextController.callNext, stepData[currentStep].time_to_move_next_button_ms)
  }
}
