import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ProgressBar from 'react-bootstrap/ProgressBar'
import LSMenuCont from '../menu/containers/menuCont'
import LSHelpTagCont from '../helpTag/containers/helpTag'
import LSProgressBar from '../progressBar/containers/progressBarCont';
import { logo } from '../../themes/currentTheme';
import withSizes from 'react-sizes';

class LSHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (<div className="ls-header">
      <Container>
        <Row>
          <Col xs={4}>
            {this.props.respRender ? <LSMenuCont /> : null}
          </Col>
          <Col xs={4} className="centerContent">
            <a href="https://www.lumen-research.com/" rel="noopener noreferrer" target="_blank"><img className="logo" alt="Lumen Research Logo" src={logo}/></a>
          </Col>
          <Col xs={4} style={{zIndex: 1}}>
            <LSHelpTagCont className="desktopOnly"/>
          </Col>
        </Row>
      </Container>
      {this.props.progressBarTiming !== 0 ? <LSProgressBar /> : null}
    </div>);
  }
}

const mapSizesToProps = ({ width, height }) => ({
  respRender: width > 812 && height > 420,
})

export default withSizes(mapSizesToProps)(LSHeader)
