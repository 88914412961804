import React from 'react';
import {connect} from 'react-redux';
import App from './App'

function LSAppCont(props) {
  return (<App basestate={props.basestate} selectedLanguage={props.selectedLanguage}/>)
}

const mapStateToProps = function(state) {
  return {
    selectedLanguage: state.modalReducer.selectedLanguage,
    basestate: state.baseReducer.basestate
  }
}

export default connect(mapStateToProps)(LSAppCont);
