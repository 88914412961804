import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button';
import {FormattedMessage} from 'react-intl';
import platform from 'platform';
import StudyNextController from '../studyController/studyNextController';
import ROLLBAR from '../../../rollbar.js'
import { demo1 } from './demoTypes';

/**
 * This is the footer component. Controls the users ability to continue the study.
 */
export default class LSFooter extends React.Component {
  constructor(props) {
    super(props);
    this.barSwitch = this.barSwitch.bind(this);
    this.progressController = this.progressController.bind(this);
  }

  indexBar = () => {
    return (<Col className="centerAlign" xs={12}>
      <Button disabled={!this.props.progressAvailable} onClick={this.progressController} className="button-primary">
        <FormattedMessage id="app.button.start" defaultMessage="Start" description="Start Button"/>
      </Button>
    </Col>)
  }

  studyBar = () => {
    return (<Col className="rightAlign" xs={12}>
      <Button disabled={!this.props.progressAvailable} onClick={this.progressController} className="button-primary">
        <FormattedMessage id="app.button.next" defaultMessage="Next" description="Next Button"/>
      </Button>
    </Col>)
  }

  progressController() {
    var componentRef = this;
    switch (this.props.basestate) {
      case "index":

      let brokerDetails = {
        eyetracking_enabled: true,
        ir_enabled: false,
        js_enabled: true,
        min_frame_rate: 0,
        min_resolution_height: 0,
        min_resolution_width: 0,
        model_name: "",
        name: "LumenJS Dev",
        url: "https://lr-webcam-tracker.viewpoints.fyi/development/models/simon/"
      }
      var startPayload = {
          brokerDetails: brokerDetails,
          cleanedParticipantReference: null,
          demographicSurveyUrl: null,
          demographicSurveyUrlMemberInsertParameter: null
        }
        componentRef.props.startResponseUpdate(startPayload);

        let stepData = {

        }
        var assignPayload = {
          cellStatus: null,
          cellId: null,
          participantDetails: null,
          participantStatus: null,
          participantId: null,
          stepData: demo1
        }
        componentRef.props.assignResponseUpdate(assignPayload);

        componentRef.props.progressState();
        return
      case "prestart":
        return
      case "study":
        return StudyNextController.callNext();
      default:
        return this.props.progressState()
    }
  }

  barSwitch() {
    switch (this.props.basestate) {
      case "index":
        return this.indexBar()
      case "prestart":
        return this.indexBar()
      default:
        return this.studyBar()
    }
  }

  render() {
    return (<div className="ls-footer">
      <Container>
        <Row>
          {this.barSwitch()}
        </Row>
      </Container>
    </div>);
  }
}
