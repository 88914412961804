import React from 'react';
// DEMO ALT
import StudyNextController from 'components/studyController/studyNextController';
import ErrorHandler from '../../util/errorHandler';
import LSIntlFormat from '../../util/intlFormat';

function detectQueryString(currentUrl) {
    // regex pattern for detecting ? character
    var pattern = new RegExp(/\?+/g);

    return pattern.test(currentUrl);
}

export default class LSStudyRedirectStep extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    var componentRef = this;
    var currentStep = this.props.currentStepOrderId;
    //var currentStepId = this.props.stepData[currentStep].id;
    var redirectUrlQsBool = detectQueryString(this.props.stepData[currentStep].url);
    var redirectUrl = this.props.stepData[currentStep].url;
    redirectUrl = redirectUrl.concat((redirectUrlQsBool ? "&session_id=" : "?session_id=") + this.props.studyReducer.sessionId);
    redirectUrl = redirectUrl.concat((this.props.selectedLanguage !== "en" ? "&next=" + LSIntlFormat.IntlMessageFormat("app.button.next", "Next") : ""))
    redirectUrl = redirectUrl.concat((process.env.REACT_APP_LUMEN_ENV_VALUE === "release" ? "" : "&env=dev"));
    redirectUrl = redirectUrl.concat((this.props.studyReducer.brokerDetails.url.includes("no_eyetracking_broker") ? "&et_type=NOET" : ""));
    redirectUrl = redirectUrl.concat((this.props.studyReducer.brokerDetails.url.includes("/mouse_broker/") ? "&et_type=MB" : ""));
    redirectUrl = redirectUrl.concat((this.props.studyReducer.brokerDetails.url.includes("/mobile_mouse_broker/") ? "&et_type=MMB" : ""));
    let cb = (userId, success, sessionId) => {
      if (!success) {
        let e = new Error("Redirect Step Failure");
        ErrorHandler(e)
      } else {
        window.location.href = redirectUrl;
      }
    }
    this.props.et.inContextRedirect(componentRef.props.studyReducer.studyId, componentRef.props.studyReducer.participantId, [], currentStep, cb, componentRef.props.studyReducer.cellId, process.env.REACT_APP_LUMEN_ENV_VALUE, componentRef.props.studyReducer.stepData, this.props.stepData[currentStep].id, componentRef.props.studyReducer.showGaze);
  }

  render() {
    return (
      null
    );
  }
}
